import {
  AssembledComponentApiDto,
  AssembledComponentSkuApiDto,
  AssembledSkuToCartRequestApiDto,
  ProductApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useCartApi } from './api/useCartApi';
import { useProductsApi } from './api/useProductsApi';
import { useAppStaticContext } from './AppContext';
import { useSearch } from './useSearch';

export interface UseProductAssemblerProps {
  // multipliers?: Record<string, number>;
  multiplierAttributeCode?: string;
  productId: string | undefined;
}

export type AssembledProduct = {
  assembledComponents?: Array<AssembledComponent>;
  completed: boolean;
  totalPrice: number;
};

export type AssembledComponent = Omit<AssembledComponentApiDto, 'componentSkus'> & {
  completed: boolean;
  componentSkus?: Array<AssembledComponentSku>;
  curQty: number;
};

export type AssembledComponentSku = AssembledComponentSkuApiDto & {
  addEnabled: boolean;
  addQuantity(): void;
  inCurrentFilter: boolean;
  multiplier: number;
  quantity: number;
  remove(): void;
  removeEnabled: boolean;
  removeQuantity(): void;
};

export const useProductAssembler = ({ multiplierAttributeCode, productId }: UseProductAssemblerProps) => {
  const [product, setProduct] = React.useState<ProductApiDto>();

  const { getProduct } = useProductsApi();
  const { onAddToCartSuccess } = useAppStaticContext();

  React.useEffect(() => {
    productId &&
      getProduct(productId, {
        populate: {
          assembledComponents: {
            componentSkus: {
              price: true,
              sku: {
                price: true,
                product: { attributes: true, brand: true },
              },
            },
          },
          brand: true,
          skus: { price: true },
        },
      }).then((response) => {
        setProduct(response.data);
      });
  }, [getProduct, productId]);

  const { addAssembledSku } = useCartApi();

  type Selection = Record<
    string, // component id
    | Array<{
        id: string;
        quantity: number;
      }>
    | undefined
  >;

  const [selection, setSelection] = React.useState<Selection>({});

  const getComponentCurrentQuantity = React.useCallback(
    (
      selectionForThisComponent?: Array<{
        id: string;
        quantity: number;
      }>
    ) => {
      return selectionForThisComponent?.reduce<number>((prev, curr) => prev + curr.quantity, 0) ?? 0;
    },
    []
  );

  const multipliers: Record<string, number> | undefined = React.useMemo(() => {
    const result: Record<string, number> = {};
    product?.assembledComponents?.forEach((assembledComponent) => {
      assembledComponent.componentSkus?.forEach((componentSku) => {
        // if (componentSku.sku && componentSku.sku.price?.value) {
        if (componentSku.sku) {
          result[componentSku.sku.id] = Number(
            componentSku.sku.product?.attributes?.find((attribute) => attribute.typeCode === multiplierAttributeCode)
              ?.value ?? 1
          );
        }
      });
    });
    return result;
  }, [multiplierAttributeCode, product?.assembledComponents]);

  const addSkuQuantity = React.useCallback(
    (component: AssembledComponentApiDto, skuId: string) => {
      const multiplier = multipliers[skuId];

      setSelection((prevState) => {
        let selectionForThisComponent = prevState[component.id];
        if (selectionForThisComponent === undefined) {
          selectionForThisComponent = [];
        }
        const currentQuantityForThisComponent = getComponentCurrentQuantity(selectionForThisComponent);
        if (currentQuantityForThisComponent === component.maxQty) {
          throw new Error('useProductAssembler, impossibile aggiungere un altro sku, si è già raggiunta la maxQty.');
        }
        const skuInSelection = selectionForThisComponent.find(({ id }) => id === skuId);
        const skuAlreadyInSelection = skuInSelection !== undefined;
        const newState = {
          ...prevState,
          [component.id]: skuAlreadyInSelection
            ? selectionForThisComponent.map(({ id, quantity }) => ({
                id: id,
                quantity: id === skuId ? quantity + 1 * multiplier : quantity,
              }))
            : selectionForThisComponent.concat({ id: skuId, quantity: 1 * multiplier }),
        };
        return newState;
      });
    },
    [getComponentCurrentQuantity, multipliers]
  );

  const removeSkuQuantity = React.useCallback(
    (component: AssembledComponentApiDto, skuId: string) => {
      setSelection((prevState) => {
        const multiplier = multipliers[skuId];
        const selectionForThisComponent = prevState[component.id];
        if (selectionForThisComponent === undefined) {
          throw new Error('useProductAssembler, impossibile rimuovere uno sku da un component senza selezioni');
        }
        const skuInSelection = selectionForThisComponent.find(({ id }) => id === skuId);
        if (skuInSelection === undefined) {
          throw new Error(
            'useProductAssembler, impossibile rimuovere uno sku che non è stato precedentemente selezionato'
          );
        }
        const isQuantityMoreThen1 = skuInSelection.quantity - 1 * multiplier > 1;

        const newState = {
          ...prevState,
          [component.id]: isQuantityMoreThen1
            ? selectionForThisComponent.map(({ id, quantity }) => ({
                id: id,
                quantity: id === skuId ? quantity - 1 * multiplier : quantity,
              }))
            : selectionForThisComponent.filter(({ id }) => id !== skuId),
        };
        return newState;
      });
    },
    [multipliers]
  );

  const removeSku = React.useCallback((component: AssembledComponentApiDto, skuId: string) => {
    setSelection((prevState) => {
      const selectionForThisComponent = prevState[component.id];
      if (selectionForThisComponent === undefined) {
        throw new Error('useProductAssembler, impossibile rimuovere uno sku da un component senza selezioni');
      }
      const newState = {
        ...prevState,
        [component.id]: selectionForThisComponent.filter(({ id }) => id !== skuId),
      };
      return newState;
    });
  }, []);

  const productsIds = React.useMemo(
    () =>
      product?.assembledComponents?.reduce<Array<string>>((curr, prev) => {
        prev.componentSkus?.map(
          (componentSkus) => componentSkus.sku?.product?.id && curr.push(componentSkus.sku.product.id)
        );
        return curr;
      }, []),
    [product?.assembledComponents]
  );

  const { searchResult } = useSearch({
    basePath: window.location.pathname,
    defaultPageSize: productsIds?.length ?? 0,
    populate: {
      filters: true,
      items: true,
    },
    products: productsIds,
    thingsToLoadBeforeSearch: [productsIds],
  });

  const assembledProduct = React.useMemo<AssembledProduct | undefined>(
    () =>
      product && searchResult
        ? {
            assembledComponents: product.assembledComponents?.map((assembledComponent) => {
              const selectionForThisComponent = selection[assembledComponent.id];
              const currentQuantityForThisComponent = getComponentCurrentQuantity(selectionForThisComponent);
              return {
                ...assembledComponent,
                completed: currentQuantityForThisComponent >= assembledComponent.minQty,
                componentSkus: assembledComponent.componentSkus?.map((componentSku) => {
                  if (componentSku.sku === undefined) {
                    throw new Error('componentSku.sku undefined');
                  }
                  const multiplier = multipliers[componentSku.sku.id];
                  const quantity =
                    selectionForThisComponent?.find(({ id }) => componentSku.sku?.id === id)?.quantity ?? 0;
                  return {
                    ...componentSku,
                    addEnabled: currentQuantityForThisComponent + multiplier <= assembledComponent.maxQty,
                    addQuantity: () => {
                      componentSku.sku && addSkuQuantity(assembledComponent, componentSku.sku.id);
                    },
                    inCurrentFilter: searchResult.items?.some(({ id }) => id === componentSku.sku?.product?.id) ?? true,
                    multiplier: multipliers[componentSku.sku.id],
                    quantity: quantity / multiplier,
                    remove: () => {
                      componentSku.sku && removeSku(assembledComponent, componentSku.sku.id);
                    },
                    removeEnabled: quantity > 0,
                    removeQuantity: () => {
                      componentSku.sku && removeSkuQuantity(assembledComponent, componentSku.sku.id);
                    },
                  };
                }),
                curQty: currentQuantityForThisComponent,
                selectedComponentSkus: [],
              };
            }),
            completed:
              product.assembledComponents?.reduce<boolean>((prev, curr) => {
                const selectionForThisComponent = selection[curr.id];
                const currentQuantityForThisComponent = getComponentCurrentQuantity(selectionForThisComponent);
                const isThisComponentCompleted = currentQuantityForThisComponent >= curr.minQty;
                return prev && isThisComponentCompleted;
              }, true) ?? false,
            totalPrice:
              product.assembledComponents?.reduce<number>((acc, assembledComponent) => {
                const selectionForThisComponent = selection[assembledComponent.id];
                return (
                  acc +
                  (assembledComponent.componentSkus?.reduce<number>((acc2, componentSku) => {
                    const quantity =
                      selectionForThisComponent?.find(({ id }) => componentSku.sku?.id === id)?.quantity ?? 0;
                    return acc2 + quantity * (componentSku.price?.value ?? 0);
                  }, 0) ?? 0)
                );
              }, 0) ?? 0,
          }
        : undefined,
    [
      addSkuQuantity,
      getComponentCurrentQuantity,
      multipliers,
      product,
      removeSku,
      removeSkuQuantity,
      searchResult,
      selection,
    ]
  );

  const prices: Record<string, number> | undefined = React.useMemo(() => {
    const result: Record<string, number> = {};
    product?.assembledComponents?.forEach((assembledComponent) => {
      assembledComponent.componentSkus?.forEach((componentSku) => {
        if (componentSku.sku && componentSku.price?.value) {
          result[componentSku.sku.id] = componentSku.price.value;
        }
      });
    });
    return result;
  }, [product?.assembledComponents]);

  const addToCart = React.useCallback(() => {
    product?.skus &&
      product.skus[0]?.id &&
      assembledProduct &&
      addAssembledSku({
        componentSkus: Object.entries(selection).reduce<Array<AssembledSkuToCartRequestApiDto>>(
          (prev, [assembledComponentId, skus]) =>
            prev.concat(
              skus
                ? skus.map<AssembledSkuToCartRequestApiDto>(({ id, quantity }) => ({
                    assembledComponentId: assembledComponentId,
                    id: id,
                    price: prices[id] * quantity,
                    quantity: quantity,
                  }))
                : []
            ),
          []
        ),
        id: product.skus[0].id,
        price: assembledProduct.totalPrice,
        quantity: 1,
      }).then(() => {
        onAddToCartSuccess && onAddToCartSuccess();
      });
  }, [addAssembledSku, assembledProduct, onAddToCartSuccess, prices, product?.skus, selection]);

  React.useEffect(() => {
    product?.assembledComponents?.forEach((assembledComponent) => {
      if (
        assembledComponent.componentSkus?.length === 1 &&
        assembledComponent.defaultQty === 1 &&
        assembledComponent.minQty === 1 &&
        assembledComponent.maxQty === 1
      ) {
        const componentSkuId = assembledComponent.componentSkus.at(0)?.sku?.id;

        componentSkuId && addSkuQuantity(assembledComponent, componentSkuId);
      }
    });
  }, [addSkuQuantity, product?.assembledComponents]);

  return { addToCart, assembledProduct, product, searchResult };
};
