import { b2x } from '@b2x/react/src';

import { CardsListSectionContentType } from './contentTypes';

export interface CardsListSectionProps {
  cardsList: CardsListSectionContentType;
}

export const CardsListSection = ({ cardsList }: CardsListSectionProps) => {
  return (
    <b2x.EqualHeight>
      <b2x.Row className="align-center" gap={3}>
        {cardsList.map((card) => (
          <b2x.Col className="col-12 col-md-4" key={card.contentSectionId}>
            <div className={`bg-${card.backgroundColor} text-${card.color} text-start pb-3 position-relative`}>
              <b2x.EqualHeightElement name="advantage-card-image">
                <b2x.ImageFromContent {...card.img} className="w-100" fluid />
              </b2x.EqualHeightElement>
              {card.cta?.label && <b2x.DeprecatedCta className="stretched-link" cta={card.cta} />}
              <b2x.EqualHeightElement name="advantage-card-title">
                {card.title && <h3 className="mt-3 mx-3 fw-bold">{b2x.formatHtml(card.title)}</h3>}
              </b2x.EqualHeightElement>
              <b2x.EqualHeightElement name="advantage-card-content">
                {card.content && <p className="mx-3">{b2x.formatHtml(card.content)}</p>}
              </b2x.EqualHeightElement>
              <b2x.EqualHeightElement name="advantage-card-cta">
                {card.cta && (
                  <div className="text-end">
                    <b2x.DeprecatedCta className={`btn btn-${card.cta.variant} text-white`} cta={card.cta}>
                      <span className="small">{b2x.formatHtml(card.cta.label)}</span>
                    </b2x.DeprecatedCta>
                  </div>
                )}
              </b2x.EqualHeightElement>
            </div>
          </b2x.Col>
        ))}
      </b2x.Row>
    </b2x.EqualHeight>
  );
};
