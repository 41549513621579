import { AssetContentSection } from './contentTypes';
import { ImageFromContent } from './ImageFromContent';
import { VideoFromContent } from './VideoFromContent';

export interface AssetProps extends AssetContentSection {
  className?: string;
  fluid?: boolean;
}

export const Asset = ({ className, fluid, image, type, video }: AssetProps) => {
  return (
    <>
      {type === 'image' && image && <ImageFromContent {...image} className={className} fluid={fluid} />}
      {type === 'video' && video && <VideoFromContent {...video} className={className} />}
    </>
  );
};
