import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import SwiperInterface, { FreeMode, Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Accordion } from '../Accordion';
import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import { ProductBannerContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon, IconName, IconSize } from '../Icon';
import { ProductReviews } from '../ProductReviews';
import { ProductSlider } from '../slider/ProductSlider';
import { customerIsEmployee, customerIsVet } from '../util';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });
  const { headerHeight } = useAppContext();
  const [reviewsRef, scrollToReviews] = b2x.useScrollTo(-(headerHeight ?? 0));

  const [variantsDivContainer, scrollToVariantsDivContainer] = b2x.useScrollTo(-180);

  const { session } = b2x.useAppContext();
  const { setStickersProductContent } = b2x.useAppStaticContext();

  const stickersVetContent = b2x.useContent<b2x.contentTypes.StickersProductContentType>(
    'STICKERS_PRODUCT_CONTENT_VET',
    undefined,
    { suppressErrorLog: true }
  );

  const stickersEmployeeContent = b2x.useContent<b2x.contentTypes.StickersProductContentType>(
    'STICKERS_PRODUCT_CONTENT_EMPLOYEE',
    undefined,
    { suppressErrorLog: true }
  );

  React.useEffect(() => {
    if (session?.customer?.qualifiers) {
      customerIsVet(session.customer.qualifiers)
        ? setStickersProductContent(stickersVetContent)
        : customerIsEmployee(session.customer.qualifiers) && setStickersProductContent(stickersEmployeeContent);
    }
  }, [session?.customer, setStickersProductContent, stickersEmployeeContent, stickersVetContent]);

  return (
    <Page className="product-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <>
              <section className="product-main pt-3 mb-5">
                <Container>
                  {/* <b2x.AddToCartFormHelper product={product} scope="product"> */}
                  <b2x.AddToCartFormHelper product={product} scope="product">
                    {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                      <b2x.Row>
                        <b2x.Col size={{ lg: 6, md: 12, xl: 6 }}>
                          <div className="gallery-sticky-container">
                            <ProductImageGallery
                              headerHeight={headerHeight}
                              selectedProductVariant={selectedProductVariant}
                              selectedSku={selectedSku}
                            />
                            <div
                              className="btn-wishlist-container position-absolute top-0 end-0 p-3"
                              style={{ zIndex: 100 }}
                            >
                              <b2x.WishlistButtonHelper product={product} sku={selectedSku}>
                                {({ handleWishlistButtonClick, inWishlist }) => (
                                  <Button
                                    iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 25 }}
                                    onClick={handleWishlistButtonClick}
                                    variant="blank"
                                  />
                                )}
                              </b2x.WishlistButtonHelper>
                            </div>
                          </div>
                        </b2x.Col>
                        <b2x.Col size={{ lg: 6, md: 12, xl: 5 }}>
                          <div className="ps-lg-5">
                            <div className="mb-4">
                              <Breadcrumb hiddenLastItem />
                              {selectedProductVariant.brand && (
                                <div className="mb-1 text-uppercase">{selectedProductVariant.brand.name}</div>
                              )}
                              {selectedProductVariant.tags?.includes('PUR_NEW') && (
                                <div>{t('misc.newProductSticker')}</div>
                              )}
                              {product.name && (
                                <h1 className="h2 fw-bold mb-3">
                                  {b2x.formatHtml(product.name)}
                                  {selectedSku?.name && selectedSku.name.trim() !== '' && (
                                    <span className="fw-light">{b2x.formatHtml(` (${selectedSku.name})</span>`)}</span>
                                  )}
                                </h1>
                              )}
                              <b2x.SummaryReviewsPreview
                                className="mb-3"
                                productId={product.id}
                                productSummaryReviews={product.summaryReviews}
                                scrollTo={scrollToReviews}
                              />
                              {selectedProductVariant.descriptionShort && (
                                <div className="short-description mb-4">
                                  {b2x.formatHtml(selectedProductVariant.descriptionShort)}
                                </div>
                              )}
                              <ProductPrice priceHelper={priceHelper} />
                              {selectedProductVariant.offers && selectedProductVariant.offers.length > 0 && (
                                <div className="mb-4">
                                  <b2x.Row gap={3}>
                                    {selectedProductVariant.offers.map((offer) => (
                                      <b2x.Col className="extra-small" key={offer.id} size={{ lg: 10, xs: 12 }}>
                                        <div className="d-inline-block bg-primary text-white px-2 py-1 fw-bold">
                                          {offer.shortDescription}
                                        </div>
                                        <div className="bg-gray-200 p-2">{offer.description}</div>
                                      </b2x.Col>
                                    ))}
                                  </b2x.Row>
                                </div>
                              )}
                              {fieldsHelper.skus.formFields.length > 1 && (
                                <b2x.FormGroup {...fieldsHelper.skus.formGroup} noMarginBottom>
                                  <b2x.Select {...fieldsHelper.skus.select}>
                                    {fieldsHelper.skus.formFields.map(({ option, sku }) => (
                                      <b2x.Option key={sku.id} {...option} />
                                    ))}
                                  </b2x.Select>
                                </b2x.FormGroup>
                              )}
                              <div ref={variantsDivContainer}>
                                <b2x.FormGroup
                                  {...fieldsHelper.productVariants.formGroup}
                                  className="mb-4"
                                  labelClassName="small fw-bold"
                                >
                                  {fieldsHelper.productVariants.formFields.map((formField, index) => (
                                    <b2x.Radio
                                      key={formField.productVariant.id}
                                      {...formField.radio}
                                      inline
                                      labelClassName="small text-secondary border border-1 border-gray-200 bg-gray-200 py-1 px-2"
                                    >
                                      {formField.productVariant.skus?.map(
                                        (sku, indexSku) => indexSku === 0 && sku.name
                                      )}
                                    </b2x.Radio>
                                  ))}
                                </b2x.FormGroup>
                              </div>
                              <div className="d-flex mb-3">
                                {selectedSku?.state === 'AVAILABLE' && (
                                  <b2x.FormGroup
                                    {...fieldsHelper.quantity.formGroup}
                                    className="text-center me-3"
                                    label={undefined}
                                    noMarginBottom
                                  >
                                    <b2x.Select
                                      {...fieldsHelper.quantity.select}
                                      className="text-center"
                                      includeEmptyOption={false}
                                      placeholder={undefined}
                                      style={{ maxWidth: '100px' }}
                                    />
                                  </b2x.FormGroup>
                                )}
                                {/* <b2x.Button {...fieldsHelper.buttons.submit} /> */}
                                <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                  fieldsHelper={fieldsHelper}
                                  selectedSku={selectedSku}
                                />
                              </div>
                              <div className="mb-2">
                                <b2x.InstallmentPaymentBanner amount={priceHelper.price} />
                              </div>
                              {session?.cart?.shippingProfile?.freeThreshold && (
                                <div className="small text-primary">
                                  <Icon className="me-1" name="delivery" size={20} />
                                  {b2x.formatHtml(
                                    t('product.freeShippingFrom', {
                                      freeShippingFromAmount: b2x.formatCurrency(
                                        session.cart.shippingProfile.freeThreshold
                                      ),
                                    })
                                  )}
                                </div>
                              )}
                            </div>
                            <ProductBanner attributes={product.attributes} customer={session?.customer} />
                            <ProductAccordion
                              product={product}
                              selectedSku={selectedSku}
                              selectedVariant={selectedProductVariant}
                            />
                            <b2x.Portal>
                              <b2x.ProductStickyFooter
                                fieldsHelper={fieldsHelper}
                                formik={formik}
                                priceHelper={priceHelper}
                                product={product}
                                scrollToElement={scrollToVariantsDivContainer}
                                selectedProductImage={selectedProductVariant.image}
                                selectedSku={selectedSku}
                              />
                            </b2x.Portal>
                          </div>
                        </b2x.Col>
                      </b2x.Row>
                    )}
                  </b2x.AddToCartFormHelper>
                </Container>
              </section>
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts &&
                    relatedProducts.length > 0 && (
                      <section className="related-products mb-5">
                        <Container>
                          <h3 className="h1 fw-bold mb-5">{t('product.relatedProduct.title')}</h3>
                          <b2x.Listing
                            name="Product page - Related products"
                            products={relatedProducts}
                            sendEvent={false}
                          >
                            <ProductSlider products={relatedProducts} />
                          </b2x.Listing>
                        </Container>
                      </section>
                    )
                  )
                }
              </b2x.RelatedProducts>
              <ProductReviews innerRef={reviewsRef} product={product} />
            </>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductBannerPros {
  attributes?: Array<b2x.AttributeApiDto>;
  customer: b2x.CustomerApiDto | undefined;
}

const ProductBanner = ({ attributes, customer }: ProductBannerPros) => {
  const { getPagePath } = b2x.useAppStaticContext();

  // Variabili contenenti gli attributi prodotto per i banner (uno per ogni tipologia di utente).
  const attributeVet = 'PUR_BANNER_VET';
  const attributeEmployee = 'PUR_BANNER_DIP';
  const attributeUser = 'PUR_BANNER';

  // Imposto nella variabile l'attributo in base alla tipologia utente (vet, employee o user).
  const productBannerAttribute =
    customer?.qualifiers && customerIsVet(customer.qualifiers)
      ? attributeVet
      : customer?.qualifiers && customerIsEmployee(customer.qualifiers)
      ? attributeEmployee
      : attributeUser;

  // Recupero il valore dall'attributo impostato.
  const productBannerContentCode = attributes?.find(
    (attribute) => attribute.typeCode === productBannerAttribute
  )?.value;

  // Se presente un valore, recupero il contenuto del banner.
  const content = productBannerContentCode && b2x.useContent<ProductBannerContentType>(productBannerContentCode);

  return (
    <>
      {typeof content === 'object' && content.body.img && (
        <div className="product-banner mb-4">
          <b2x.ConditionalWrapper
            condition={content.body.to?.code !== undefined || content.body.to?.href !== undefined}
            wrapper={
              <b2x.router.Link
                to={
                  content.body.to?.href
                    ? content.body.to.href
                    : content.body.to?.code && getPagePath(content.body.to.code)
                }
              />
            }
          >
            <b2x.ImageFromContent {...content.body.img} fluid />
          </b2x.ConditionalWrapper>
        </div>
      )}
    </>
  );
};

interface ProductPriceProps {
  priceHelper: b2x.UsePriceResult;
}

const ProductPrice = ({ priceHelper }: ProductPriceProps) => {
  return (
    <div className="product-price-container mb-4">
      <div className="mb-1">
        <b2x.PriceBlock priceHelper={priceHelper} />
      </div>
      {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
        <div className="fw-light">
          <b2x.BestPrice priceHelper={priceHelper} />
        </div>
      )}
    </div>
  );
};

interface ProductImageGalleryProps {
  headerHeight?: number;
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductImageGallery = ({ headerHeight, selectedProductVariant, selectedSku }: ProductImageGalleryProps) => {
  // const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  // const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperInterface>();
  const [gallerySwiper, setGallerySwiper] = React.useState<SwiperInterface>();

  const gallery = b2x.useGallery(selectedProductVariant, selectedSku);

  const { stickersProductWithImage, stickersProductWithoutImage } = b2x.useStickersProduct(selectedProductVariant);

  React.useEffect(() => {
    if (selectedSku) {
      gallerySwiper?.slideTo(0);
    }
  }, [gallerySwiper, selectedSku]);

  const breakpoint = b2x.useBreakpoint();

  return (
    <div className="product-image-gallery" style={{ top: headerHeight ? `calc(${headerHeight}px + 1rem)` : undefined }}>
      <div className="gallery-container position-relative mb-3">
        <b2x.Lightbox>
          <Swiper
            className="rounded overflow-hidden h-100"
            modules={[FreeMode, Thumbs, Pagination]}
            onSwiper={setGallerySwiper}
            pagination
            slidesPerView={1}
            spaceBetween={0}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
            watchSlidesProgress
          >
            {gallery.slides.map((slide, index) => (
              <SwiperSlide
                className="text-center"
                // eslint-disable-next-line react/no-array-index-key
                key={`product-slide-${index}`}
              >
                {(slideData) => (
                  <div className="product-slide-image-container d-flex align-items-center justify-content-center">
                    {slide.type === 'image' && slide.src ? (
                      <b2x.LightboxItem bcomImageId={slide.uId} className="d-block bg-gray-100 w-100" src={slide.src}>
                        <b2x.Image aspectRatio={b2x.appConfig.productImageAspectRatio} fluid src={slide.src} />
                      </b2x.LightboxItem>
                    ) : (
                      slide.type === 'video' && (
                        <b2x.DeprecatedVideoFromContent
                          autoplay
                          controls
                          src={{ xs: { preview: { url: slide.thumbnail }, video: slide.src } }}
                        />
                      )
                    )}
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </b2x.Lightbox>
        {/*         <div
          className="gallery-zoom-text position-absolute bottom-0 start-0 w-100 py-4 px-2 text-center text-gray-400 extra-small"
          style={{ pointerEvents: 'none', zIndex: 100 }}
        >
          <Icon className="me-1" name={'search'} size={14} />
          {t('product.gallery.zoom')}
        </div> */}
        {(stickersProductWithoutImage || stickersProductWithImage) && (
          <div
            className="position-absolute p-2 start-0 w-100"
            style={{ bottom: b2x.untilBreakpoint(breakpoint, 'md') ? 0 : 35, pointerEvents: 'none', zIndex: 100 }}
          >
            <b2x.Row alignItems={'end'}>
              {stickersProductWithoutImage && (
                <b2x.Col>
                  <b2x.Row gap={2}>
                    {stickersProductWithoutImage.map((sticker) => (
                      <b2x.Col key={sticker.code} size={'auto'}>
                        <b2x.Div
                          className={classnames(
                            sticker.code,
                            'small fw-bold',
                            { 'bg-primary': !sticker.backgroundColor },
                            { 'border border-primary': !sticker.borderColor },
                            { 'text-white': !sticker.textColor },
                            { small: b2x.untilBreakpoint(breakpoint, 'md') },
                            { 'extra-small': !b2x.untilBreakpoint(breakpoint, 'md') }
                          )}
                          paddingX={1}
                          style={{
                            backgroundColor: sticker.backgroundColor,
                            border: sticker.borderColor ? `1px solid ${sticker.borderColor}` : undefined,
                            color: sticker.textColor,
                          }}
                        >
                          {sticker.label}
                        </b2x.Div>
                      </b2x.Col>
                    ))}
                  </b2x.Row>
                </b2x.Col>
              )}
              {stickersProductWithImage && (
                <b2x.Col size={'auto'}>
                  <div style={{ maxWidth: b2x.untilBreakpoint(breakpoint, 'md') ? 90 : 65 }}>
                    <b2x.Row gap={2}>
                      {stickersProductWithImage.map((sticker) => (
                        <b2x.Col key={sticker.code} size={12}>
                          <b2x.ImageFromContent {...sticker.image} fluid />
                        </b2x.Col>
                      ))}
                    </b2x.Row>
                  </div>
                </b2x.Col>
              )}
            </b2x.Row>
          </div>
        )}
      </div>
      <div className="thumbnail-container mb-3 position-relative">
        <Swiper
          allowSlideNext
          allowSlidePrev
          className="flex-grow-1 auto-width"
          freeMode
          modules={[Thumbs, Navigation, FreeMode]}
          // navigation={{
          //   nextEl: sliderNextButton.current,
          //   prevEl: sliderPrevButton.current,
          // }}
          onSwiper={setThumbsSwiper}
          slidesPerView={'auto'}
          spaceBetween={16}
          watchSlidesProgress
        >
          {gallery.slides.map((slide, index) => (
            <SwiperSlide
              className="bg-gray-100 border border-gray-100 rounded overflow-hidden"
              // eslint-disable-next-line react/no-array-index-key
              key={`product-thumbnail-${index}`}
            >
              {(slideData) =>
                slide.thumbnail && (
                  <div>
                    <b2x.Image
                      aspectRatio={b2x.appConfig.productImageAspectRatio}
                      fluid
                      height={120}
                      src={slide.thumbnail}
                      width={120}
                    />
                  </div>
                )
              }
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
        <SliderButton direction="right" innerRef={sliderNextButton} size="small" /> */}
      </div>
    </div>
  );
};

interface ProductAccordionProps {
  product: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
  selectedVariant?: b2x.ProductApiDto;
}

const ProductAccordion = ({ product, selectedSku, selectedVariant }: ProductAccordionProps) => {
  return (
    <Accordion
      className="product-accordion"
      id="product-accordion"
      itemBodyClassName="pt-0 px-0"
      itemButtonClassName="fw-bold px-0"
      itemClassName="border-black"
    >
      <b2x.AccordionItem id="product-description" show title={t('product.description')}>
        {product.description && <div className="mb-3">{b2x.formatHtml(product.description)}</div>}
        {selectedVariant?.code && (
          <div className="text-primary">
            {t('product.code')}: {selectedVariant.code.replace('B2X_', '')}
          </div>
        )}
      </b2x.AccordionItem>
      {product.attributes?.map(
        (attribute) =>
          attribute.typeCode !== 'PUR_VIDEO_URL' &&
          attribute.typeCode !== 'PUR_VIDEO_IMAGE_URL' &&
          attribute.typeCode !== 'PUR_BANNER' &&
          attribute.typeCode !== 'PUR_BANNER_DIP' &&
          attribute.typeCode !== 'PUR_BANNER_VET' &&
          attribute.typeCode !== 'PUR_PICKMIX_FOTO' &&
          attribute.typeCode !== 'PUR_PICKMIX_MULTIPLIER' && (
            <b2x.AccordionItem id={attribute.typeCode} key={attribute.typeCode} title={attribute.title ?? ''}>
              {b2x.formatHtml(attribute.value)}
            </b2x.AccordionItem>
          )
      )}
    </Accordion>
  );
};
