import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ArticlePageContentType } from './contentTypes';
import { t } from './i18n/i18n';

export interface ArticleTileProps extends ArticlePageContentType {
  classname?: string;
  cta?: string;
  onlyOneFormatForImagePreview?: boolean;
  pages?: Array<b2x.PageApiDto>;
}
export const ArticleTile = ({
  classname,
  cta,
  imagePreview,
  onlyOneFormatForImagePreview,
  pages,
  publicationDate,
  summary,
  title,
}: ArticleTileProps) => {
  return (
    <div className={classnames('article-tile', classname)}>
      <b2x.ConditionalWrapper
        condition={cta !== undefined}
        wrapper={<b2x.router.Link style={{ display: 'block' }} to={cta} />}
      >
        <b2x.EqualHeightElement disable={['xs', 'sm']} name="article-tile-image">
          {/*  {onlyOneFormatForImagePreview && imagePreview?.src?.xs ? (
            <b2x.Image className="mb-3" fluid src={{ xs: imagePreview.src.xs }} />
          ) : (
            <b2x.ImageFromContent className="mb-3" fluid {...imagePreview} />
          )} */}
          <b2x.NotResponsiveImageFromContent className="mb-4" fluid {...imagePreview} />
        </b2x.EqualHeightElement>
      </b2x.ConditionalWrapper>

      <div className="content-wrapper">
        {publicationDate && <h6 className="text-uppercase extra-small">{b2x.formatHtml(publicationDate)}</h6>}
        {title && (
          <b2x.EqualHeightElement disable={['xs', 'sm']} name="article-tile-title">
            <h3 className="fw-bold title mb-2 h2">{b2x.formatHtml(title)}</h3>
          </b2x.EqualHeightElement>
        )}

        {summary && (
          <b2x.EqualHeightElement disable={['xs', 'sm']} name="article-tile-summary">
            <div className="small mb-3">{b2x.formatHtml(summary)}</div>
          </b2x.EqualHeightElement>
        )}

        <div className="content-bottom-wrapper d-flex justify-content-between align-items-start">
          {pages && (
            <div className="tags d-flex flex-wrap me-3">
              {pages.map((page) => (
                <b2x.router.Link
                  className="btn-link me-2 badge rounded-pill bg-gray-200 fw-normal text-dark"
                  key={page.id}
                  to={page.fullPath}
                >
                  #{b2x.formatHtml(page.name)}
                </b2x.router.Link>
              ))}
            </div>
          )}
          {cta && (
            <b2x.router.Link className="align-self-start small text-black" to={cta}>
              {t('misc.blog.readArticle')}
            </b2x.router.Link>
          )}
        </div>
      </div>
    </div>
  );
};
