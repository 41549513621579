import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from '../Button';
import { MiscellaneousContentType } from '../contentTypes';
import { ResourceKeys, t } from '../i18n/i18n';

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesFormValues = {
  topics: Array<b2x.formikString>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{
  topics: b2x.yup.ArraySchema<b2x.yup.StringSchema>;
}>;

export type NewsletterFormProps = Omit<
  b2x.NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'validationSchemaSelector'
>;

export const NewsletterForm = ({
  ctaContainerClassName,
  emailFieldStyle = 'textInput',
  ...otherProps
}: NewsletterFormProps) => {
  const misceContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  return (
    <b2x.NewsletterFormHelper<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>
      {...otherProps}
      additionalProperties={{
        formValues: {
          NEWSLETTER_TOPICS_OF_INTEREST: (values) =>
            values.additionalProperties ? values.additionalProperties.topics.join(',') : '',
        },
        initialValues: { topics: [] },
        validationSchema: b2x.yup.object({ topics: b2x.yup.array().of(b2x.yup.string()) }),
      }}
      emailFieldStyle={emailFieldStyle}
      redirectToHomeOnSuccessModalClose
      validationSchemaSelector={{ name: false, surname: false }}
    >
      {({ fieldsHelper }) => (
        <>
          {emailFieldStyle === 'inputGroup' && (
            <b2x.FormGroup {...fieldsHelper.email.formGroup} className="custom-icon-btn-group" label={undefined}>
              <b2x.InputGroup {...fieldsHelper.email.inputGroup}>
                <b2x.TextInput {...fieldsHelper.email.textInput} />
                <Button
                  {...fieldsHelper.buttons.submit}
                  className="bg-primary text-white px-4"
                  label={t('form.newsletterForm.buttons.submit.label')}
                  size="small"
                  variant="blank"
                  //iconEnd={{ name: 'small-arrow-right' }}
                />
              </b2x.InputGroup>
            </b2x.FormGroup>
          )}
          {emailFieldStyle === 'textInput' && (
            <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
              <b2x.TextInput {...fieldsHelper.email.textInput} />
            </b2x.FormGroup>
          )}
          <b2x.FormGroup names={[]}>
            {['cat', 'dog'].map((topic) => (
              <b2x.Checkbox
                id={topic}
                inline
                key={topic}
                label={t(`form.newsletterForm.topics.${topic}` as ResourceKeys)}
                labelClassName="small"
                name="additionalProperties.topics"
                value={topic}
              />
            ))}
          </b2x.FormGroup>

          <b2x.FormGroup
            {...fieldsHelper.newsletterConsent.formGroup}
            className="mt-1"
            label={t('form.customerForm.privacyConsent.label')}
          >
            {misceContent?.body.privacyBoxArea && (
              <div className="privacy-box pt-1 mt-1 mb-3">
                <div className="px-3 py-1 fw-light extra-small">{b2x.formatHtml(misceContent.body.privacyBoxArea)}</div>
              </div>
            )}
            <b2x.Checkbox {...fieldsHelper.newsletterConsent.checkbox} labelClassName="extra-small text-start mb-3" />
          </b2x.FormGroup>
          {emailFieldStyle === 'textInput' && (
            <div className={classnames('cta-container', ctaContainerClassName)}>
              <b2x.Button label={t('form.newsletterForm.buttons.submit.label')} {...fieldsHelper.buttons.submit} />
            </div>
          )}
        </>
      )}
    </b2x.NewsletterFormHelper>
  );
};
