import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from './Container';
import { ImageBannerContentType } from './contentTypes';

export interface ImageBannerProps extends ImageBannerContentType {
  className?: string;
}

export const ImageBanner = ({
  alignment,
  className,
  content,
  firstCta,
  img,
  secondCta,
  title,
  titleColor,
}: ImageBannerProps) => {
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <div className={classnames('image-banner position-relative', className)}>
      {img && (
        <div className="media-container text-center">
          <b2x.ImageFromContent {...img} className="order-1" fluid />
        </div>
      )}
      <div
        className={classnames(
          'text-container',
          { 'position-absolute top-0 start-0': !b2x.untilBreakpoint('md', currentBreakpoint) },
          'd-flex align-items-center w-100 h-100 py-3 py-lg-5'
        )}
      >
        <Container>
          <b2x.Row
            className={classnames(
              !b2x.untilBreakpoint('md', currentBreakpoint) && alignment === 'left' && 'justify-content-start',
              !b2x.untilBreakpoint('md', currentBreakpoint) && alignment === 'center' && 'justify-content-center',
              !b2x.untilBreakpoint('md', currentBreakpoint) && alignment === 'right' && 'justify-content-end',
              b2x.untilBreakpoint('md', currentBreakpoint) && 'justify-content-center'
            )}
          >
            <b2x.Col size={{ lg: 6, xs: 12 }}>
              <div
                className={classnames(
                  !b2x.untilBreakpoint('md', currentBreakpoint) && alignment === 'left' && 'text-start',
                  !b2x.untilBreakpoint('md', currentBreakpoint) && alignment === 'center' && 'text-center',
                  !b2x.untilBreakpoint('md', currentBreakpoint) && alignment === 'right' && 'text-end',
                  b2x.untilBreakpoint('md', currentBreakpoint) && 'text-center'
                )}
              >
                {title && (
                  <h4
                    className={classnames(
                      { h6: b2x.untilBreakpoint('md', currentBreakpoint) },
                      !b2x.untilBreakpoint('md', currentBreakpoint) && `text-${titleColor}`,
                      'fw-bold'
                    )}
                  >
                    {b2x.formatHtml(title)}
                  </h4>
                )}
                {content && (
                  <p
                    className={classnames(
                      'text-primary fw-bold ff-purina mt-1',
                      { h1: !b2x.untilBreakpoint('md', currentBreakpoint) },
                      { h3: b2x.untilBreakpoint('md', currentBreakpoint) }
                    )}
                  >
                    {b2x.formatHtml(content)}
                  </p>
                )}

                {firstCta?.label && (
                  <div className="d-md-inline-block mt-4">
                    <b2x.DeprecatedCta
                      className={classnames(firstCta.label && ` px-5 btn btn-${firstCta.variant}`)}
                      cta={firstCta}
                    >
                      {b2x.formatHtml(firstCta.label)}
                    </b2x.DeprecatedCta>
                  </div>
                )}
                {secondCta?.label && (
                  <div className="d-md-inline-block mt-3 mt-md-0 mx-md-4 mt-4">
                    <b2x.DeprecatedCta
                      className={classnames(secondCta.label && `px-5 btn btn-${secondCta.variant}`)}
                      cta={secondCta}
                    >
                      {b2x.formatHtml(secondCta.label)}
                    </b2x.DeprecatedCta>
                  </div>
                )}
              </div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
    </div>
  );
};
