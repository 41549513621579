import './LandingTemplateBPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { AssembledProductTile } from '../AssembledProductTile';
import { LandingTemplateBPageContentType } from '../contentTypes';
import { Slider } from '../slider/Slider';
import { Page } from './Page';

export const LandingTemplateBPage = () => {
  const page = b2x.usePage<LandingTemplateBPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const { assembledProduct, product } = b2x.useProductAssembler({
    multiplierAttributeCode: 'PUR_PICKMIX_MULTIPLIER',
    productId: page?.products?.at(0)?.id,
  });

  const headerSectionList = page?.content?.body.headerSectionList;
  const productSlider = page?.content?.body.productSlider;
  const componentSkus = assembledProduct?.assembledComponents?.at(0)?.componentSkus?.slice(0, 10);
  const sectionWithBoxes = page?.content?.body.sectionWithBoxes;

  const currentBreakpoint = b2x.useBreakpoint();

  const componentSkuPrice = React.useMemo(() => {
    let minQty = product?.assembledComponents?.at(0)?.minQty;
    minQty = minQty && minQty / 24;

    let price = product?.skus?.at(0)?.price?.value;
    price = price && minQty && price / minQty;

    let crossedOutPrice = product?.skus?.at(0)?.price?.crossedOutValue;
    crossedOutPrice = crossedOutPrice && minQty && crossedOutPrice / minQty;

    return { crossedOutPrice, price };
  }, [product?.assembledComponents, product?.skus]);

  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <Page
      className="landing-template-b-page"
      noPaddingBottom
      noPaddingTop
      thingsToLoadBeforePageReady={[assembledProduct]}
    >
      {headerSectionList?.map(({ assetSection, contentSectionId, copySection }) => (
        <section className="header-section-list mb-4 mb-md-5 text-center" key={contentSectionId}>
          <b2x.router.Link to={assetSection?.to?.code ? getPagePath(assetSection.to.code) : assetSection?.to?.href}>
            <b2x.Asset className={classnames({ 'mb-3 mb-lg-5': copySection?.title })} {...assetSection?.asset} fluid />
          </b2x.router.Link>
          <b2x.Container>
            {copySection?.title && (
              <h3
                className={classnames('h1 mb-3 text-gold', {
                  'fs-4': b2x.untilBreakpoint('md', currentBreakpoint),
                })}
              >
                {copySection.title}
              </h3>
            )}
            {copySection?.description && (
              <div
                className={classnames(
                  'text-center text-lg-start',
                  { small: b2x.untilBreakpoint('md', currentBreakpoint) },
                  { 'fs-4': !b2x.untilBreakpoint('md', currentBreakpoint) }
                )}
              >
                {b2x.formatHtml(copySection.description)}
              </div>
            )}
            {copySection?.cta && (
              <div className="text-center">
                <b2x.CtaFromContent {...copySection.cta} />
              </div>
            )}
          </b2x.Container>
        </section>
      ))}
      {componentSkus && (
        <section className="products mb-5 pt-3 pt-lg-5">
          <b2x.Container>
            {productSlider?.title && <h3 className="h1 mb-4 text-gold">{productSlider.title}</h3>}
            <div className="position-relative">
              <b2x.EqualHeight>
                <Slider
                  navigation
                  responsive={{
                    lg: {
                      slidesPerView: 3.4,
                    },
                    sm: {
                      slidesPerView: 2.4,
                    },
                    xl: {
                      slidesPerView: 4,
                    },
                  }}
                  slidesPerView={1.4}
                  spaceBetween={20}
                >
                  {componentSkus.map((componentSku) => (
                    <SwiperSlide key={componentSku.code}>
                      <AssembledProductTile
                        componentSku={componentSku}
                        componentSkuCrossedOutPrice={componentSkuPrice.crossedOutPrice}
                        componentSkuPrice={componentSkuPrice.price}
                        productUrl={page?.products?.at(0)?.url}
                        viewOnly
                      />
                    </SwiperSlide>
                  ))}
                </Slider>
              </b2x.EqualHeight>
            </div>
          </b2x.Container>
        </section>
      )}

      {sectionWithBoxes && (
        <section className="section-with-boxes mb-5">
          <b2x.Container>
            {(sectionWithBoxes.title || sectionWithBoxes.content) && (
              <div className="text-center mb-4 mb-lg-5">
                {sectionWithBoxes.title && (
                  <h3
                    className={classnames('mb-lg-3 mb-xl-5 text-gold', {
                      'fs-4': b2x.untilBreakpoint('md', currentBreakpoint),
                    })}
                  >
                    {sectionWithBoxes.title}
                  </h3>
                )}
                {sectionWithBoxes.content && (
                  <div
                    className={classnames(
                      'text-center text-lg-start',
                      { small: b2x.untilBreakpoint('md', currentBreakpoint) },
                      { 'fs-4': !b2x.untilBreakpoint('md', currentBreakpoint) }
                    )}
                  >
                    {b2x.formatHtml(sectionWithBoxes.content)}
                  </div>
                )}
              </div>
            )}
            {sectionWithBoxes.boxList && sectionWithBoxes.boxList.length > 0 && (
              <div className="mb-4 mb-xl-5">
                <b2x.Row gap={3}>
                  {sectionWithBoxes.boxList.map((box) => (
                    <b2x.Col key={box.contentSectionId} size={{ md: 4, xs: 12 }}>
                      {box.asset && (
                        <div className="box-asset-container mb-3">
                          <b2x.Asset {...box.asset} fluid />
                        </div>
                      )}
                      <div
                        className="box-copy text-center text-md-start pb-3 pb
                      -lg-0"
                      >
                        {box.title && (
                          <h4
                            className={classnames({ 'fs-6': b2x.untilBreakpoint('md', currentBreakpoint) }, 'fw-bold')}
                          >
                            {box.title}
                          </h4>
                        )}
                        {box.content && (
                          <div
                            className={classnames(
                              { 'fs-6': b2x.untilBreakpoint('md', currentBreakpoint) },
                              { 'fs-5': !b2x.untilBreakpoint('md', currentBreakpoint) },
                              'box-content-container'
                            )}
                          >
                            {b2x.formatHtml(box.content)}
                          </div>
                        )}
                      </div>
                    </b2x.Col>
                  ))}
                </b2x.Row>
              </div>
            )}
            {sectionWithBoxes.cta && (
              <div className="text-center">
                <b2x.CtaFromContent {...sectionWithBoxes.cta} />
              </div>
            )}
          </b2x.Container>
        </section>
      )}
    </Page>
  );
};
