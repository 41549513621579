import './ArticlePage.scss';

import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { ArticlePageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { ProductTile } from '../ProductTile';
import { Slider } from '../slider/Slider';
import { Page } from './Page';

export interface ArticlePageProps {}

export const ArticlePage = (props: ArticlePageProps) => {
  /* const page = b2x.usePage<ArticlePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  }); */

  const page = b2x.useContentByPath<ArticlePageContentType>({ populate: { pages: true } });

  return (
    <Page className="article-page border-bottom" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {/*page?.body.imagePreview && <PageHeader className={'mb-lg-5 mb-3'} media={{ img: page.body.imagePreview }} />*/}
      <div className="bg-gray-100">
        <Container>
          <b2x.Row>
            <b2x.Col className="order-md-last d-none d-md-block" size={{ md: 6, xs: 12 }}>
              <div
                className="h-100 d-flex flex-column justify-content-end align-items-center"
                style={{
                  backgroundImage: page?.body.imagePreview ? `url(${page.body.imagePreview})` : 'none',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  minHeight: '400px',
                }}
              ></div>
              {/* {page?.body.imagePreview && <b2x.Image className="d-md-none" fluid src={page.body.imagePreview} />} */}
            </b2x.Col>
            <b2x.Col className="pt-4 pb-5" size={{ md: 6, xs: 12 }}>
              <Breadcrumb contentCodeListToHide={['ART_BLOG_CATEGORY']} lastItemLinkable />
              {page?.body.imagePreview && (
                <b2x.NotResponsiveImageFromContent className="mb-3 d-md-none" fluid {...page.body.imagePreview} />
              )}
              {page?.body.title && <h1 className="fw-bold mt-3">{b2x.formatHtml(page.body.title)}</h1>}
              {page?.body.publicationDate && (
                <h6 className="text-uppercase mt-4">{b2x.formatHtml(page.body.publicationDate)}</h6>
              )}
              {page?.body.summary && <div className="summary mt-4 mt-md-5">{b2x.formatHtml(page.body.summary)}</div>}
              {page?.pages && page.pages.length > 0 && (
                <div className="tags d-flex flex-wrap mt-4">
                  {page.pages.map((cat) => (
                    <b2x.router.Link
                      className="btn-link me-2 badge rounded-pill bg-gray-200 fw-normal text-dark text-decoration-none"
                      key={cat.id}
                      to={cat.fullPath}
                    >
                      #{b2x.formatHtml(cat.name)}
                    </b2x.router.Link>
                  ))}
                </div>
              )}
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>

      {page?.body.content && (
        <Container>
          <b2x.Row>
            <b2x.Col size={{ xs: 12 }}>
              <div className="article-content my-3 my-lg-5">{b2x.formatHtml(page.body.content)}</div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}

      {page?.body.productsList && page.body.productsList.length > 0 && (
        <section className="related-products mb-5">
          <Container>
            <h3 className="h1 fw-bold mb-5">{t('product.relatedProduct.title')}</h3>
            <div className="product-slider position-relative">
              <b2x.ProductsByIds ids={page.body.productsList.map(({ productId }) => productId)}>
                {(products) => (
                  <b2x.EqualHeight>
                    <b2x.Listing name="Article page" products={products}>
                      <Slider
                        navigation
                        responsive={{
                          lg: {
                            slidesPerView: 3.4,
                          },
                          sm: {
                            slidesPerView: 2.4,
                          },
                          xl: {
                            slidesPerView: 4,
                          },
                        }}
                        slidesPerView={1.4}
                        spaceBetween={20}
                      >
                        {products?.map((product) => {
                          return (
                            <SwiperSlide key={product.id}>
                              <ProductTile product={product} />
                            </SwiperSlide>
                          );
                        })}
                      </Slider>
                    </b2x.Listing>
                  </b2x.EqualHeight>
                )}
              </b2x.ProductsByIds>
            </div>
          </Container>
        </section>
      )}
    </Page>
  );
};
