import { b2x } from '@b2x/react/src';
import React from 'react';

import { Box } from '../Box';
import { RegistrationFormContentType } from '../contentTypes';
import { CustomerEmployeeForm } from '../forms/CustomerEmployeeForm';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export interface RegistrationEmployeePageProps {}

export const RegistrationEmployeePage = (props: RegistrationEmployeePageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_EMPLOYEE_FORM_CONTENT');

  const { callbackUrl } = b2x.useAppContext();
  const { setCallbackUrl } = b2x.useAppStaticContext();
  const navigate = b2x.router.useNavigate();

  const { showModal } = b2x.useModals();

  const redirectTo = React.useCallback(() => {
    callbackUrl ? navigate(callbackUrl) : navigate('/');
    setCallbackUrl(undefined);
  }, [callbackUrl, navigate, setCallbackUrl]);

  const successfulRegistration = React.useCallback(() => {
    showModal({
      children: b2x.formatHtml(content?.body.modal?.content),
      fullscreen: 'md',
      onClose: redirectTo,
      size: 'default',
      title: content?.body.modal?.title || t('misc.customerForm.registrationSuccessful.modal.title'),
    });
  }, [content?.body.modal?.content, content?.body.modal?.title, redirectTo, showModal]);

  return (
    <Page thingsToLoadBeforePageReady={[content]}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <Box>
              <div className="mb-4 text-center">
                <h1 className="h2 fw-bold mb-2">{b2x.formatHtml(content?.body.title)}</h1>
                <p className="mb-0">{b2x.formatHtml(content?.body.subTitle)}</p>
              </div>
              <CustomerEmployeeForm minAge={18} onSuccess={successfulRegistration} />
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
