import './MobileNavigationOffcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { Container } from './Container';
import { HeaderMenuItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo-mobile.svg';
import { deepFilterMenuByCustomerType } from './util';

interface MobileNavigationOffcanvasProps extends b2x.OffcanvasProps {}

const MobileNavigationOffcanvas = (props: MobileNavigationOffcanvasProps) => {
  const { session } = b2x.useAppContext();

  const menuToBefilteredByCustomerType = b2x.useMenu('MENU_HEADER_MOBILE', {
    // deepFilter: (_menu) => deepFilterMenuByCustomerType(_menu, session),
    populate: { content: true },
  });

  const menu = React.useMemo<b2x.MenuApiDto | undefined>(
    () =>
      menuToBefilteredByCustomerType
        ? deepFilterMenuByCustomerType(menuToBefilteredByCustomerType, session)
        : undefined,
    [menuToBefilteredByCustomerType, session]
  );

  return (
    <b2x.Offcanvas {...props}>
      <div className="mobile-navigation-offcanvas d-flex flex-column h-100">
        <Header />
        <TopSuggestionsMobile />
        <div className="flex-grow-1 _overflow-auto position-relative">
          <b2x.Drawers itemsClassName="px-3">
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">{menu && <DrawerMenuItems item={menu} />}</div>
              <Footer />
            </div>
          </b2x.Drawers>
        </div>
      </div>
    </b2x.Offcanvas>
  );
};

const TopSuggestionsMobile = () => {
  const { close } = b2x.useModalContext();

  const onSuccess = React.useCallback(() => {
    close();
  }, [close]);

  return (
    <b2x.template.TopSuggestions
      className="pt-3 overflow-auto"
      clearIconName="delete"
      onSuccess={onSuccess}
      submitIconName="search"
    />
  );
};

const Header = () => {
  const { close } = b2x.useModalContext();

  return (
    <div className="border-bottom">
      <Container>
        <b2x.Row className="py-2">
          <b2x.Col size={''}>
            <b2x.router.Link className="logo d-flex align-items-center" onClick={close} to="/">
              <b2x.Image fluid src={logo} />
            </b2x.router.Link>
          </b2x.Col>
          <b2x.Col size={'auto'}>
            <Button
              className="menu-button fw-normal"
              iconEnd={{ name: 'delete', size: 20 }}
              onClick={close}
              variant="blank"
            />
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};

const Footer = () => {
  const { info } = b2x.useAppContext();
  return (
    <div className="p-3 pb-5">
      {info?.customerCarePhoneNumber && (
        <>
          <h6 className="fw-bold">{t('customerCare.contact')}</h6>
          <div className="d-flex flex-column">
            {
              //eslint-disable-next-line react/forbid-elements
              <a className="text-black" href={`tel:${info.customerCarePhoneNumber}`}>
                {info.customerCarePhoneNumber}
              </a>
            }
          </div>
        </>
      )}
    </div>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = t('misc.back') }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
      <b2x.DrawerItem
        center={{ center: label }}
        className="gap-2 small fw-normal"
        height={42}
        justifyContent="start"
        start={{ center: <Icon name="small-arrow-left" size={16} /> }}
      />
    </b2x.DrawerButtonItem>
  );
};

interface DrawerMenuItemsProps {
  item: b2x.MenuApiDto;
}

const DrawerMenuItems = ({ item }: DrawerMenuItemsProps) => {
  return (
    <>
      {item.children.map((child) => {
        const hasChildren = child.children.length > 0;

        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={child.id}
            newDrawer={
              <Drawer backItemProps={{ label: child.label }}>
                <DrawerMenuItems item={child} />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className="fw-normal"
              end={{ center: <Icon name="small-arrow-right" /> }}
              height={42}
              justifyContent="between"
              start={{ center: child.label }}
            />
          </b2x.DrawerTriggerItem>
        ) : child.link ? (
          <b2x.DrawerLinkItem key={child.id} to={child.link}>
            <b2x.DrawerItem
              className={classnames(
                'fw-normal',
                { 'ff-purina': child.tags?.includes('purinaScript') },
                { 'text-gold': child.tags?.includes('gold') }
              )}
              height={42}
              justifyContent="start"
              start={{ center: child.label }}
            />
          </b2x.DrawerLinkItem>
        ) : (
          child.content && (
            <b2x.DrawerTriggerItem
              key={child.id}
              newDrawer={
                <Drawer backItemProps={{ label: child.label }}>
                  <DrawerMenuItems item={child} />
                </Drawer>
              }
            >
              <b2x.DrawerItem
                className="fw-normal mb-1"
                end={{ center: <Icon name="small-arrow-right" /> }}
                height={42}
                justifyContent="between"
                start={{ center: child.label }}
              />
            </b2x.DrawerTriggerItem>
          )
        );
      })}
      {item.link && (
        <b2x.DrawerLinkItem to={item.link}>
          <b2x.DrawerItem
            className="fw-bold mb-3"
            height={42}
            justifyContent="start"
            start={{ center: t('misc.viewAll') }}
          />
        </b2x.DrawerLinkItem>
      )}
      {item.content &&
        b2x.typedContent<HeaderMenuItemContentType>(item.content, (content) => (
          <div className="bg-gray-200 p-3 pb-4" style={{ minHeight: '100%' }}>
            <h6 className="fw-bold mb-2 py-2">{content.body.title}</h6>
            {content.body.bannerList && content.body.bannerList.length > 0 && (
              <b2x.Row className="justify-content-between" gap={3}>
                {content.body.bannerList.map(
                  (slide) =>
                    slide.img?.src?.xs && (
                      <b2x.Col
                        className="d-flex justify-content-center align-items-center"
                        key={slide.contentSectionId}
                        size={12}
                      >
                        <div className="position-relative text-center">
                          <b2x.ImageFromContent fluid {...slide.img} />
                          {(slide.cta?.to?.href || slide.cta?.to?.code) && (
                            <b2x.DeprecatedCta
                              className={classnames('stretched-link', {
                                'd-block py-3 px-2 bg-white small fw-bold text-black': slide.cta.label,
                              })}
                              cta={slide.cta}
                            >
                              {slide.cta.label}
                            </b2x.DeprecatedCta>
                          )}
                        </div>
                      </b2x.Col>
                    )
                )}
              </b2x.Row>
            )}
          </div>
        ))}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} label={undefined} />
      <h5 className="px-3 fw-bold py-3 mb-0 border-bottom">{backItemProps?.label}</h5>
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

export const useMobileNavigationOffcanvas = (props?: MobileNavigationOffcanvasProps) =>
  b2x.useStaticModal(MobileNavigationOffcanvas, 'offcanvas', props);
