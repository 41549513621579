import './GiftCardPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Container } from '../Container';
import { GiftCardContentType } from '../contentTypes';
import { CustomRadio } from '../CustomRadio';
import { Page } from './Page';

export interface GiftCardPageProps {}

export const GiftCardPage = (props: GiftCardPageProps) => {
  const product = b2x.useProduct({ populate: { priceRange: true, skus: { attributes: true, price: true } } });
  const content = b2x.useContent<GiftCardContentType>('GIFT_CARD_CONTENT');

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[product]}>
      <div className="d-grid">
        {content?.body.banner?.img && (
          <div className="h-100 w-100" style={{ gridColumn: 1, gridRow: 1 }}>
            <b2x.ImageFromContent {...content.body.banner.img} className="w-100" fluid />
          </div>
        )}
        <div
          className="g-row-0 d-flex align-items-center justify-content-center text-center flex-column w-100 py-5"
          style={{ background: content?.body.banner?.background, gridColumn: 1, gridRow: 1, minHeight: '100%' }}
        >
          {content?.body.banner?.title && (
            <h1 className="text-white fw-bold display-1 mb-0">{b2x.formatHtml(content.body.banner.title)}</h1>
          )}
          {content?.body.banner?.subTitle && (
            <b2x.Col size={{ md: 6, xs: 10 }}>
              <h5 className="text-white fw-normal mt-3">{b2x.formatHtml(content.body.banner.subTitle)}</h5>
            </b2x.Col>
          )}
        </div>
      </div>
      <Container className="pt-md-3 mt-0">
        {product && (
          <b2x.AddToCartFormHelper
            className="mt-5"
            imageSkuVariantAttributeTypeCode="Gift_card_design"
            product={product}
            scope="product"
          >
            {({ fieldsHelper, image }) => {
              console.log(fieldsHelper.skuAttributes);
              return (
                <b2x.Row>
                  <b2x.Col size={{ md: 7, xs: 12 }}>
                    <div>
                      <h1 className="fw-bold">{content?.body.stepsContainer?.title}</h1>
                      <p className="fw-normal mt-3 me-lg-5 me-0">{content?.body.stepsContainer?.subTitle}</p>
                      <div className="img-container">
                        <b2x.Image {...image} className="mb-4 mb-md-5" fluid />
                      </div>
                      <div className="me-lg-5 me-0">
                        {content?.body.stepsContainer?.stepsList?.map(({ contentSectionId, description }, index) => (
                          <div className="mb-3 pb-3" key={contentSectionId}>
                            <span className="h3 fw-bold me-3">{index + 1}</span>
                            {b2x.formatHtml(description)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </b2x.Col>
                  <b2x.Col size={{ md: 1, xs: 12 }} />
                  <b2x.Col size={{ md: 4, xs: 12 }}>
                    <div className="mt-md-0 mt-3">
                      <h2 className="fw-bold mb-1">{content?.body.buyContainer?.title}</h2>
                      <p>{content?.body.buyContainer?.subTitle}</p>
                      <p className="extra-small">{content?.body.buyContainer?.description}</p>
                      {fieldsHelper.skuAttributes
                        .filter((skuAttribute) => skuAttribute.attribute.typeCode === 'Gift_card_valore')
                        .map((skuAttribute) => (
                          <React.Fragment key={skuAttribute.attribute.typeCode}>
                            <h6 className="border-bottom fw-semi-bold mb-3 pb-3 small">
                              1. {content?.body.buyContainer?.formLabels?.value}
                            </h6>
                            <b2x.FormGroup {...skuAttribute.formGroup}>
                              <b2x.Select {...skuAttribute.select} />
                            </b2x.FormGroup>
                          </React.Fragment>
                        ))}
                      {fieldsHelper.skuAttributes
                        .filter((skuAttribute) => skuAttribute.attribute.typeCode === 'Gift_card_design')
                        .map((skuAttribute) => (
                          <React.Fragment key={skuAttribute.attribute.typeCode}>
                            <div className="design-container">
                              <h6 className="border-bottom fw-semi-bold mb-3 py-3 small">
                                2. {content?.body.buyContainer?.formLabels?.design}
                              </h6>
                              <b2x.FormGroup {...skuAttribute.formGroup} label={undefined}>
                                {skuAttribute.radios.map(({ radio, skus }) => (
                                  <b2x.Radio key={radio.id} {...radio} className="me-3 mb-2">
                                    <CustomRadio imageUrl={skus[0]?.image?.src} isSquare />
                                  </b2x.Radio>
                                ))}
                              </b2x.FormGroup>
                            </div>
                          </React.Fragment>
                        ))}
                      {/* {Object.entries(fieldsHelper.skuAttributes).map(
                        ([name, { attribute, formGroup, radios, select }]) => (
                          <div key={name}>
                            {attribute.typeCode === 'Gift_card_valore' ? (
                              <>
                                <h6 className="border-bottom fw-semi-bold mb-3 pb-3 small">
                                  1. {content?.body.buyContainer?.formLabels?.value}
                                </h6>
                                <b2x.FormGroup {...formGroup}>
                                  <b2x.Select {...select} />
                                </b2x.FormGroup>
                              </>
                            ) : (
                              <div className="design-container">
                                <h6 className="border-bottom fw-semi-bold mb-3 py-3 small">
                                  2. {content?.body.buyContainer?.formLabels?.design}
                                </h6>
                                <b2x.FormGroup {...formGroup} label={undefined}>
                                  {radios.map(({ radio, skus }) => (
                                    <b2x.Radio key={radio.id} {...radio} className="me-3 mb-2">
                                      <CustomRadio imageUrl={skus[0]?.image?.src} isSquare />
                                    </b2x.Radio>
                                  ))}
                                </b2x.FormGroup>
                              </div>
                            )}
                          </div>
                        )
                      )} */}
                      {fieldsHelper.giftCard && (
                        <div>
                          <h6 className="border-bottom fw-semi-bold mb-3 py-3 small">
                            3. {content?.body.buyContainer?.formLabels?.message}
                          </h6>
                          <b2x.FormGroup {...fieldsHelper.giftCard.senderName.formGroup}>
                            <b2x.TextInput {...fieldsHelper.giftCard.senderName.textInput} />
                          </b2x.FormGroup>
                          <b2x.FormGroup {...fieldsHelper.giftCard.receiverName.formGroup}>
                            <b2x.TextInput {...fieldsHelper.giftCard.receiverName.textInput} />
                          </b2x.FormGroup>
                          <b2x.FormGroup {...fieldsHelper.giftCard.receiverEmail.formGroup}>
                            <b2x.TextInput {...fieldsHelper.giftCard.receiverEmail.textInput} />
                          </b2x.FormGroup>
                          <b2x.FormGroup {...fieldsHelper.giftCard.message.formGroup}>
                            <b2x.TextArea {...fieldsHelper.giftCard.message.textArea} />
                          </b2x.FormGroup>
                        </div>
                      )}
                      <div className="d-grid">
                        <b2x.Button {...fieldsHelper.buttons.submit} />
                      </div>
                    </div>
                  </b2x.Col>
                </b2x.Row>
              );
            }}
          </b2x.AddToCartFormHelper>
        )}
        {!!content?.body.howItWorks?.faqList?.length && (
          <div className="mt-5 pt-4 pt-md-5 pb-3 pb-md-5 px-3 px-md-5 bg-gray-100">
            <h5 className="text-uppercase text-center mb-4 mb-md-5">{content.body.howItWorks.title}</h5>
            <b2x.Row cols={{ md: 2, xs: 1 }} gap={3}>
              {content.body.howItWorks.faqList.map(({ contentSectionId, description, title }) => (
                <b2x.Col key={contentSectionId}>
                  <div className="h-100 bg-white p-3 p-md-4">
                    <h6 className="fw-semi-bold">{title}</h6>
                    <small className="fw-light lh-1">{b2x.formatHtml(description)}</small>
                  </div>
                </b2x.Col>
              ))}
            </b2x.Row>
          </div>
        )}
      </Container>
    </Page>
  );
};
