import './ProductReviews.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Section } from './Section';

export interface ProductReviewsProps {
  innerRef?: React.RefObject<HTMLDivElement>;
  product: b2x.ProductApiDto;
}

export const ProductReviews = ({ innerRef, product }: ProductReviewsProps) => {
  const { setCallbackUrl } = b2x.useAppStaticContext();
  const { showAccountOffcanvas } = useAppStaticContext();
  const location = b2x.router.useLocation();

  const handleSignInButtonClick = React.useCallback(() => {
    setCallbackUrl(location.pathname);
    showAccountOffcanvas();
  }, [location.pathname, setCallbackUrl, showAccountOffcanvas]);

  return (
    <div ref={innerRef}>
      <Section className="bg-gray-100 py-5 mb-0">
        <b2x.ProductReviews onSignInButtonClick={handleSignInButtonClick} product={product} />
      </Section>
    </div>
  );
};
