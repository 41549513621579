import { ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { useAppContext } from './AppContext';

export const useStickersProduct = (product?: ProductApiDto) => {
  const { stickersProductContent } = useAppContext();

  const stickersProduct =
    product?.tags &&
    stickersProductContent?.body.stickers?.filter((sticker) => sticker.code && product.tags?.includes(sticker.code));

  const stickersProductWithImage = stickersProduct?.filter((filteredSticker) => filteredSticker.image?.src?.xs);
  const stickersProductWithoutImage = stickersProduct?.filter((filteredSticker) => !filteredSticker.image?.src?.xs);

  return { stickersProduct, stickersProductWithImage, stickersProductWithoutImage };
};
