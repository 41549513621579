import './TopSuggestionsBox.scss';

import { PageApiDto, ProductApiDto, TopSuggestionsApiDto } from '@b2x/storefront-api-js-client/src';
import classNames from 'classnames';
import qs from 'qs';
import React from 'react';

import { b2x } from '.';
import { useAppStaticContext } from './AppContext';
import { Col } from './Col';
import { Container } from './Container';
import { Div, H5, P } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { Link } from './router/Link';
import { Row } from './Row';
import { usePrice } from './usePrice';
import { formatCurrency, uiClassName } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface TopSuggestionsBoxProps {
  directories?: Array<PageApiDto>;
  onSuccess?(): void;
  products?: Array<ProductApiDto>;
  simpleSearch?: string;
  topSuggestions: TopSuggestionsApiDto;
}

const ProductBox = (product: ProductApiDto<unknown>) => {
  const { image, name } = product;
  const { crossedOutPrice, price } = usePrice(product);

  return (
    <>
      <Div alignItems="center" className="product" display="grid" gap={3} marginBottom={2}>
        <Div style={{ width: 80 }}>
          {image && (
            <Image
              className={uiClassName({
                bs5: 'bg-gray-100',
              })}
              fluid
              src={image.src}
            />
          )}
        </Div>
        <Div>
          <P className="truncate-line small" fontWeight={700} marginBottom={0}>
            {name}
          </P>
        </Div>
        <Div alignItems="end" display="flex" flexDirection="column" justifyContent="end">
          <P
            fontWeight={700}
            marginBottom={0}
            uiClassName={{
              bs5: classNames({
                'text-promo': !!crossedOutPrice,
              }),
            }}
          >
            {price ? formatCurrency(price) : undefined}
          </P>
          <P
            fontWeight={700}
            marginBottom={0}
            uiClassName={{
              bs5: 'text-decoration-line-through',
            }}
          >
            {crossedOutPrice ? formatCurrency(crossedOutPrice) : undefined}
          </P>
        </Div>
      </Div>
    </>
  );
};

const dataToSearchUrl = (data: { categories?: Array<string>; simpleSearch?: string }) =>
  `${'/search'}?${qs.stringify(data, { indices: false, skipNulls: true })}`;

const TopSuggestionsBox = ({
  directories,
  onSuccess,
  products,
  simpleSearch,
  topSuggestions,
}: TopSuggestionsBoxProps) => {
  const allProductRedirectUrl = React.useMemo(() => dataToSearchUrl({ simpleSearch }), [simpleSearch]);
  const { getPagePath } = useAppStaticContext();

  return (
    <Div
      className="top-suggestions-box"
      marginTop={{
        lg: 5,
        xs: 4,
      }}
    >
      <Container>
        <Row>
          <Col size={{ lg: 6, xl: 8, xs: 12 }}>
            <Div display="flex" flexDirection="column" justifyContent="spaceBetween" uiClassName={{ bs5: 'h-100' }}>
              {products && (
                <Row alignItems="center">
                  {products.map((props) => (
                    <Col key={props.id} size={{ xl: 6, xs: 12 }}>
                      <Link
                        className={uiClassName({ bs5: 'text-decoration-none text-reset' })}
                        onClick={onSuccess}
                        to={props.url}
                      >
                        <ProductBox {...props} />
                      </Link>
                    </Col>
                  ))}
                </Row>
              )}
              <Div marginTop={4} textAlign="center">
                <Link
                  className={uiClassName({ bs5: 'text-reset fw-bold' })}
                  onClick={onSuccess}
                  to={allProductRedirectUrl}
                >
                  {t('topSuggestionsBox.showAllProducts', { tot: topSuggestions.totalHitsByType?.PRODUCT })}
                </Link>
              </Div>
            </Div>
          </Col>
          <Col size={{ lg: 3, xl: 2, xs: 12 }}>
            <Block title={t('topSuggestionsBox.popularSearches')}>
              <Row gap={0}>
                {topSuggestions.topSuggestionsByType?.POPULAR_SEARCH?.map(({ name }) => (
                  <Col key={name} size={{ lg: 12, xs: 6 }}>
                    <Link
                      className={uiClassName({ bs5: 'text-decoration-none text-reset' })}
                      onClick={onSuccess}
                      to={dataToSearchUrl({ simpleSearch: name })}
                    >
                      <P
                        className="truncate-line"
                        data-lines="2"
                        marginBottom={2}
                        uiClassName={{
                          bs5: 'small',
                        }}
                      >
                        {name && b2x.formatHtml(b2x.boldWordInText(name, simpleSearch))}
                      </P>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Block>
          </Col>
          <Col size={{ lg: 3, xl: 2, xs: 12 }}>
            <Block title={t('topSuggestionsBox.categories')}>
              <Row>
                {directories?.map((directory) => (
                  <Col key={directory.id} size={{ lg: 12, xs: 6 }}>
                    <Link
                      className={uiClassName({ bs5: 'text-decoration-none text-reset' })}
                      onClick={onSuccess}
                      to={getPagePath(directory.code)}
                    >
                      <P
                        className="truncate-line"
                        data-lines="2"
                        marginBottom={2}
                        uiClassName={{
                          bs5: 'small',
                        }}
                      >
                        {directory.name}
                      </P>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Block>
          </Col>
        </Row>
      </Container>
    </Div>
  );
};

interface BlockProps {
  children: React.ReactNode;
  title: string;
}

const Block = ({ children, title }: BlockProps) => (
  <Div
    className="block"
    display="flex"
    flexDirection={{
      lg: 'row',
      xs: 'column',
    }}
    marginTop={{
      lg: 0,
      xs: 4,
    }}
    uiClassName={{
      bs5: 'h-100 position-relative',
    }}
  >
    <Div
      display={{
        lg: 'block',
        xs: 'none',
      }}
      style={{ width: 1 }}
      uiClassName={{ bs5: 'h-100 bg-gray-200 position-absolute' }}
    />
    <Div
      display={{
        lg: 'none',
        xs: 'block',
      }}
      style={{ height: 1 }}
      uiClassName={{ bs5: 'w-100 bg-gray-200 position-absolute' }}
    />
    <Div
      display="flex"
      flexDirection="column"
      gap={0}
      paddingStart={{
        lg: 4,
        xs: 0,
      }}
      paddingTop={{
        lg: 0,
        xs: 4,
      }}
    >
      <H5 className="title" fontWeight={700}>
        {title}
      </H5>
      {children}
    </Div>
  </Div>
);

export type TopSuggestionsBoxVariants = '';

const TopSuggestionsBoxController = (props: PropsWithCustomComponent<TopSuggestionsBoxProps>) => (
  <VariantsController<TopSuggestionsBoxProps, TopSuggestionsBoxVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: TopSuggestionsBox,
      name: 'TopSuggestionsBox',
    }}
  />
);
export { TopSuggestionsBoxController as TopSuggestionsBox };
