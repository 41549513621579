import './AssembledProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Icon } from './Icon';

interface AssembledProductTileProps {
  className?: string;
  componentSku: b2x.AssembledComponentSku;
  componentSkuCrossedOutPrice?: number;
  componentSkuPrice?: number;
  productUrl?: string;
  productsPerRow?: number;
  viewOnly?: boolean;
}

export const AssembledProductTile = ({
  className,
  componentSku,
  componentSkuCrossedOutPrice,
  componentSkuPrice,
  productUrl,
  productsPerRow,
  viewOnly,
}: AssembledProductTileProps) => {
  const currentBreakpoint = b2x.useBreakpoint();

  const productImage = componentSku.sku?.product?.attributes?.find(
    (attribute) => attribute.typeCode === 'PUR_PICKMIX_FOTO'
  )?.value;

  const pricePerUnit = componentSkuPrice && componentSkuPrice / componentSku.multiplier;

  return (
    <div className={classnames('assembled-product-tile', className)}>
      <div className="position-relative">
        {productImage ? (
          <b2x.Image fluid src={{ xs: productImage }} />
        ) : (
          <b2x.Image fluid {...componentSku.sku?.image} />
        )}
        <div className="position-absolute top-0 w-100 h-100 d-flex align-items-start justify-content-end p-2 p-md-3">
          {componentSku.quantity > 0 && (
            <div className="icon-added-container d-flex align-items-center justify-content-center bg-black rounded-circle text-white">
              <Icon name="selected" size={25} />
            </div>
          )}
        </div>
      </div>
      <div className="tile-product-info">
        <div className="component-sku-name mb-2">
          <b2x.EqualHeightElement name={`tile-info-title-${productsPerRow}`}>
            <h6 className="text-uppercase small">{componentSku.sku?.product?.brand?.name}</h6>
            <h6 className={classnames({ small: b2x.untilBreakpoint('sm', currentBreakpoint) }, 'fw-bold mb-0')}>
              {b2x.formatHtml(
                `${componentSku.sku?.product?.name} <span>${componentSku.multiplier}x${componentSku.sku?.name}</span>`
              )}
            </h6>
          </b2x.EqualHeightElement>
        </div>
        <div className="component-sku-price mb-2">
          {componentSkuPrice && componentSku.price && (
            <b2x.PriceBlock
              hiddenDiscountPercentage
              priceHelper={{
                //crossedOutPrice: componentSkuCrossedOutPrice ? componentSkuCrossedOutPrice : undefined,
                crossedOutPrice: componentSku.price.crossedOutValue
                  ? componentSku.price.crossedOutValue * componentSku.multiplier
                  : undefined,
                discountPercentage: componentSku.price.discountPercentage,
                isPriceInRange: false,
                price: componentSkuPrice,
              }}
            />
          )}

          {pricePerUnit && (
            <div className="text-gray-400 extra-small mb-2">
              {t('misc.pickAndMix.pricePerProduct', {
                price: b2x.formatCurrency(pricePerUnit),
                shape: componentSku.sku?.shape?.toLowerCase(),
              })}
            </div>
          )}
          <div className="bg-black text-white py-1 px-2 d-inline-block mb-2 small">{`${componentSku.multiplier}x${componentSku.sku?.name}`}</div>
        </div>
        {componentSku.sku && !viewOnly ? (
          <div className="component-sku-action">
            <div className="d-flex">
              <Button
                className="btn-sm p-2"
                disabled={!componentSku.removeEnabled}
                iconStart={{ name: 'minus', size: 16 }}
                onClick={componentSku.removeQuantity}
              />
              <div className="border-top border-bottom w-100 d-flex align-items-center justify-content-center">
                <div>{componentSku.quantity}</div>
              </div>
              <Button
                className="btn-sm p-2"
                disabled={!componentSku.addEnabled}
                iconStart={{ name: 'plus', size: 16 }}
                onClick={componentSku.addQuantity}
              />
            </div>
          </div>
        ) : (
          <div className="d-grid">
            <b2x.Cta button={{ label: t('misc.pickAndMix.addToMenu') }} href={productUrl} />
          </div>
        )}
      </div>
    </div>
  );
};
