import './HomePage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { CardsListSection } from '../CardsListSection';
import { Container } from '../Container';
import { HomePageContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Icon, isIconName } from '../Icon';
import { ImageBanner } from '../ImageBanner';
import { Section } from '../Section';
import { BannerSlider } from '../slider/BannerSlider';
import { ProductSlider } from '../slider/ProductSlider';
import { customerIsEmployee, customerIsVet, getContentSectionByCustomerType } from '../util';
import { Page } from './Page';
import { PopUpNewsletterModal } from './PopupNewsletterModal';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const { getDirectoryByCode } = b2x.useDirectoriesApi();
  const { pageReady, session } = b2x.useAppContext();

  const { showModal } = b2x.useModals();

  const [productsTabs, setProductsTab] = React.useState<b2x.DirectoryApiDto>();

  // const productsTabs = b2x.useDirectory('HOME_PRODUCTS', {
  //   populate: { children: { associatedDirectories: true, products: b2x.appConfig.api?.productTilePopulate } },
  // });

  // React.useEffect(() => {
  //   getDirectoryByCode('HOME_PRODUCTS', {
  //     populate: { children: { associatedDirectories: true, products: b2x.appConfig.api?.productTilePopulate } },
  //   }).then((response) => {
  //     setProductsTab(response.data);
  //     if (session?.customer?.qualifiers) {
  //       if (customerIsVet(session.customer.qualifiers)) {
  //         getDirectoryByCode('HOME_PRODUCTS_VET', {
  //           populate: { children: { associatedDirectories: true, products: b2x.appConfig.api?.productTilePopulate } },
  //         }).then((responseVet) => {
  //           setProductsTab(responseVet.data);
  //         });
  //       } else if (customerIsEmployee(session.customer.qualifiers)) {
  //         getDirectoryByCode('HOME_PRODUCTS_EMPLOYEE', {
  //           populate: { children: { associatedDirectories: true, products: b2x.appConfig.api?.productTilePopulate } },
  //         }).then((responseVet) => {
  //           setProductsTab(responseVet.data);
  //         });
  //       }
  //     }
  //   });
  // }, [getDirectoryByCode, session?.customer?.qualifiers]);

  React.useEffect(() => {
    const modalAlreadyShown = b2x.cookies.getBoolean('homePageNewsletterModal');
    if (!modalAlreadyShown && pageReady) {
      b2x.wait(3000).then(() => {
        showModal({
          bodyClassName: 'pt-0',
          children: <PopUpNewsletterModal />,
          onClose: () => {
            b2x.cookies.setBoolean('homePageNewsletterModal', true, { expires: 1 });
          },
          size: 'default',
          title: '',
        });
      });
    }
  }, [pageReady, showModal]);

  /*
    Faccio chiamata per prendere il productsTabs.
    Provo prima a prendere quello giusto in base alla qualifica del customer.
    Se non lo trovo (nel catch) prendo quello base.
  */
  React.useEffect(() => {
    const directoryCode =
      session?.customer?.qualifiers && customerIsVet(session.customer.qualifiers)
        ? 'HOME_PRODUCTS_VET'
        : session?.customer?.qualifiers && customerIsEmployee(session.customer.qualifiers)
        ? 'HOME_PRODUCTS_EMPLOYEE'
        : 'HOME_PRODUCTS';

    getDirectoryByCode(
      directoryCode,
      {
        populate: { children: { associatedDirectories: true, products: b2x.appConfig.api?.productTilePopulate } },
      },
      { silent: true, suppressErrorModal: true }
    )
      .then((response) => {
        setProductsTab(response.data);
      })
      .catch(() => {
        getDirectoryByCode(
          'HOME_PRODUCTS',
          {
            populate: { children: { associatedDirectories: true, products: b2x.appConfig.api?.productTilePopulate } },
          },
          { silent: true, suppressErrorModal: true }
        ).then((response) => {
          setProductsTab(response.data);
        });
      });
  }, [getDirectoryByCode, session?.customer?.qualifiers]);

  const promoProducts = b2x.useDirectory('HOME_WEEKLY_OFFERS', {
    populate: { children: { products: b2x.appConfig.api?.productTilePopulate } },
  });

  const body = page?.content?.body;
  const promoOfTheWeek = promoProducts?.children.find((promo) => promo.code === 'PROMO_WEEK');

  const currentBreakpoint = b2x.useBreakpoint();

  const { getPagePath } = b2x.useAppStaticContext();

  const slider = React.useMemo(
    () => getContentSectionByCustomerType(body?.sliders, session)?.slider,
    [body?.sliders, session]
  );

  const banner = React.useMemo(
    () => getContentSectionByCustomerType(body?.banners, session)?.bannerSection,
    [body?.banners, session]
  );

  const advantages = React.useMemo(
    () => getContentSectionByCustomerType(body?.advantagesSection, session)?.advantageList,
    [body?.advantagesSection, session]
  );
  const advantageTitle = React.useMemo(
    () => getContentSectionByCustomerType(body?.advantagesSection, session)?.title,
    [body?.advantagesSection, session]
  );

  return (
    <Page className="home-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page, productsTabs]}>
      <BannerSlider sliderList={slider} />
      {productsTabs && (
        <Section className="product-tabs-section my-5 ">
          <div className="mb-4 text-center px-3">
            <h1 className={classnames('mb-3 fw-bold', { h4: b2x.untilBreakpoint('lg', currentBreakpoint) })}>
              {b2x.formatHtml(body?.productsTabContentSection?.title)}
            </h1>
            <div>{b2x.formatHtml(body?.productsTabContentSection?.content)}</div>
          </div>
          <b2x.Tabs
            className="px-lg-3 home-products-tabs gap-5 justify-content-center"
            contentClassName="mt-4"
            horizontalAlignment={{ md: 'center' }}
            tabStyle="pills"
            tabs={productsTabs.children.map((item) => ({
              children: item.products && (
                <>
                  <b2x.Listing name={`Home page - ${body?.productsTabContentSection?.title}`} products={item.products}>
                    <ProductSlider products={item.products} />
                  </b2x.Listing>
                  {item.associatedDirectories && item.associatedDirectories[0].code && (
                    <div className="text-center mt-3">
                      <b2x.DeprecatedCta
                        className="btn text-black btn-link"
                        cta={{
                          to: {
                            href: getPagePath(item.associatedDirectories[0].code),
                          },
                        }}
                      >
                        {item.note}
                      </b2x.DeprecatedCta>
                    </div>
                  )}
                </>
              ),
              customTitle: (
                <div className="products-tab d-flex flex-column align-items-center">
                  {isIconName(item.iconName) && (
                    <div className="icon-container bg-gray-100 overflow-hidden rounded-circle p-3 p-lg-4 mb-3">
                      <Icon name={item.iconName} size={48} />
                    </div>
                  )}
                  <h6 className={classnames('fw-bold mb-0', { small: b2x.untilBreakpoint('lg', currentBreakpoint) })}>
                    {item.name}
                  </h6>
                </div>
              ),
              title: item.name ?? '',
            }))}
          />
        </Section>
      )}
      <ImageBanner {...banner} />
      {promoOfTheWeek?.products && (
        <Section className="promo-section my-5" textAlignment="start" title={body?.offersSection?.title}>
          <b2x.Listing name={`Home page - ${body?.offersSection?.title}`} products={promoOfTheWeek.products}>
            <ProductSlider products={promoOfTheWeek.products} />
          </b2x.Listing>
        </Section>
      )}
      <Section className="my-5" title={body?.brandsSection?.title}>
        <b2x.Tabs
          className="home-brands-tab mb-5"
          horizontalAlignment={'center'}
          tabs={[
            {
              children: (
                <HomeBrands
                  brandList={body?.brandsSection?.cat?.brandList}
                  cta={body?.brandsSection?.cat?.cta}
                  title={body?.brandsSection?.cat?.title ?? 'cat'}
                />
              ),
              customTitle: <div className="btn btn-outline-gray-300">{body?.brandsSection?.cat?.title}</div>,
              title: 'brand-cat',
            },
            {
              children: (
                <HomeBrands
                  brandList={body?.brandsSection?.dog?.brandList}
                  cta={body?.brandsSection?.dog?.cta}
                  title={body?.brandsSection?.dog?.title ?? 'dog'}
                />
              ),
              customTitle: <div className="btn btn-outline-gray-300">{body?.brandsSection?.dog?.title}</div>,
              title: 'brand-dog',
            },
          ]}
        />
      </Section>
      <Section className="my-5" textAlignment="start" title={advantageTitle}>
        {advantages && <CardsListSection cardsList={advantages} />}
      </Section>
      {body?.newsletterSection && (
        <Container className="g-3 g-lg-5">
          <b2x.Row gap={{ lg: 5, xs: 3 }}>
            <b2x.Col className="order-2 order-lg-1 d-flex align-items-end" size={{ lg: 6, xs: 12 }}>
              <div className="w-100 d-flex justify-content-center">
                <b2x.ImageFromContent {...body.newsletterSection.img} fluid />
              </div>
            </b2x.Col>
            <b2x.Col className="order-1 order-lg-2 newsletter-box" size={{ lg: 6, xs: 12 }}>
              {body.newsletterSection.title && (
                <h3 className="h2 fw-bold">{b2x.formatHtml(body.newsletterSection.title)}</h3>
              )}
              {body.newsletterSection.content && (
                <p className="newsletter-box-content">{b2x.formatHtml(body.newsletterSection.content)}</p>
              )}
              <NewsletterForm emailFieldStyle="inputGroup" source="website-footer" />
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}
    </Page>
  );
};

interface HomeBrandsProps {
  brandList?: b2x.contentTypes.ContentSectionList<{
    directoryCode?: string;
    img?: b2x.contentTypes.ImageContentSection;
  }>;
  cta?: b2x.contentTypes.CtaContentSection;
  title?: string;
}

const HomeBrands = ({ brandList, cta, title }: HomeBrandsProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <>
      <b2x.Row className="align-center justify-content-center" gap={3}>
        {brandList?.map((brand, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <b2x.Col className="col-4 col-md-3 col-lg-2 text-center" key={`${title}-${index}`}>
            <b2x.router.Link to={brand.directoryCode && getPagePath(brand.directoryCode)}>
              <b2x.ImageFromContent {...brand.img} fluid />
            </b2x.router.Link>
          </b2x.Col>
        ))}
      </b2x.Row>
      {cta && (
        <div className="text-center mt-4">
          <b2x.DeprecatedCta className={classnames('btn text-black', `btn-${cta.variant}`)} cta={cta}>
            {b2x.formatHtml(cta.label)}
          </b2x.DeprecatedCta>
        </div>
      )}
    </>
  );
};
