import './SearchFilterSlider.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { Icon, isIconName } from '../Icon';
import { Slider } from './Slider';

export interface SearchFilterSliderProps {
  filterList: Array<{ cta?: b2x.contentTypes.CtaContentSection; img?: b2x.contentTypes.ImageContentSection }>;
  page?: b2x.PageApiDto;
}

export const SearchFilterSlider = ({ filterList, page }: SearchFilterSliderProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  return (
    <div className="search-filter-slider position-relative mt-4">
      <Container>
        <div className="position-relative px-0 px-sm-5">
          <Slider
            centerInsufficientSlides
            navigation
            navigationButtonSize="small"
            responsive={{
              lg: {
                slidesPerView: 6,
              },
              md: {
                slidesPerView: 5,
              },
              sm: {
                slidesPerView: 4,
              },
              xl: {
                slidesPerView: 7,
              },
              xxl: {
                slidesPerView: 9,
              },
            }}
            slidesPerView={3.4}
            spaceBetween={0}
          >
            {filterList.map(
              (filter) =>
                filter.cta?.to?.code && (
                  <SwiperSlide key={filter.cta.to.code}>
                    <b2x.DeprecatedCta
                      className={classnames('filter text-black text-decoration-none', {
                        active: filter.cta.to.code === page?.code,
                      })}
                      cta={filter.cta}
                    >
                      <div className="filter-image-container border border-2 rounded-circle overflow-hidden d-table mx-auto mb-3 bg-white">
                        {filter.img?.src?.xs ? (
                          <b2x.ImageFromContent {...filter.img} fluid />
                        ) : (
                          isIconName(filter.cta.icon) && (
                            <div className="p-3 p-lg-4">
                              <Icon name={filter.cta.icon} size={48} />
                            </div>
                          )
                        )}
                      </div>
                      <h6
                        className={classnames('fw-bold mb-0', { small: b2x.untilBreakpoint('lg', currentBreakpoint) })}
                      >
                        {filter.cta.label}
                      </h6>
                    </b2x.DeprecatedCta>
                  </SwiperSlide>
                )
            )}
          </Slider>
        </div>
      </Container>
    </div>
  );
};
