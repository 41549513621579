import './NewsletterSubscribePage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { NewsletterPageContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Page } from './Page';

export interface NewsletterSubscribePageProps {}

export const NewsletterSubscribePage = () => {
  const content = b2x.useContent<NewsletterPageContentType>('NEWSLETTER_PAGE_CONTENT');

  return (
    <Page className="newsletter-subscribe-page" noPaddingBottom noPaddingTop>
      <div className="position-relative">
        {content?.body.img && (
          <div className="position-absolute w-100 h-100" style={{ zIndex: 10 }}>
            <b2x.ImageAsBackgroundFromContent className="w-100" {...content.body.img} />
          </div>
        )}
        <div className="page-content position-relative py-5" style={{ zIndex: 20 }}>
          <Container>
            <b2x.Row className="justify-content-md-end justify-content-center px-sm-5 px-0">
              <b2x.Col className="bg-white pt-5 pb-4 px-4" size={{ lg: 8, xl: 5, xs: 12 }}>
                <div className="copy-container text-center">
                  {content?.body.title && <h1 className="h1 fw-semi-bold">{b2x.formatHtml(content.body.title)}</h1>}
                  {content?.body.subTitle && (
                    <h5 className="mt-3 newsletter-subscribe-page-subtitle">{b2x.formatHtml(content.body.subTitle)}</h5>
                  )}
                </div>

                <div className="form-container mt-4">
                  <NewsletterForm
                    className="bg-white rounded"
                    ctaContainerClassName="mt-4 w-100"
                    emailFieldStyle="textInput"
                    source="website-modal"
                  />
                </div>

                {content?.body.cta && (
                  <div className="text-center mt-2">
                    <b2x.DeprecatedCta
                      className={`btn btn-${content.body.cta.variant} text-promo fw-bold`}
                      cta={content.body.cta}
                    >
                      {content.body.cta.label}
                    </b2x.DeprecatedCta>
                  </div>
                )}
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      </div>
    </Page>
  );
};
