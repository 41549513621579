import { b2x } from '@b2x/react/src';
import React from 'react';

import { Box } from '../Box';
import { Button } from '../Button';
import { Container } from '../Container';
import { WhoisPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export interface WhoisPageProps {}

export const WhoisPage = (props: WhoisPageProps) => {
  const [register, setRegister] = React.useState<boolean>(false);

  const handleButtonClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setRegister(true);
  }, []);

  const handleCancelButtonClick = React.useCallback(() => {
    setRegister(false);
  }, []);
  const whoisContent = b2x.useContent<WhoisPageContentType>('WHOIS_PAGE_CONTENT');

  const navigate = b2x.router.useNavigate();

  const handleLoginSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  const handleRegistrationSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  return (
    <Page className="who-is-page" headerCheckout>
      <Container>
        <b2x.Row className="justify-content-center">
          <b2x.Col size={{ md: 6, xl: 5, xs: 12 }}>
            <div className="h-100 pb-3 pb-md-0">
              <Box fullHeight>
                <div className="text-center">
                  <h3 className="fw-bold mb-3">{b2x.formatHtml(whoisContent?.body.continueAsRegisteredUser?.title)}</h3>
                  <div className="mb-4">{b2x.formatHtml(whoisContent?.body.continueAsRegisteredUser?.subTitle)}</div>
                </div>
                <b2x.LoginForm onSuccess={handleLoginSuccess} />
              </Box>
            </div>
          </b2x.Col>
          <b2x.Col size={{ md: 6, xl: 5, xs: 12 }}>
            <Box fullHeight>
              {!register && (
                <div className="mb-4">
                  {/*                   <div className="text-center">
                    <h3 className="fw-bold mb-3">{b2x.formatHtml(whoisContent?.body.continueAsGuest?.title)}</h3>
                    {<div className="mb-4">{b2x.formatHtml(whoisContent?.body.continueAsGuest?.subTitle)}</div>}
                  </div>
                  <div className="pb-4 mb-3">
                    <b2x.CartEmailFormHelper variant="whois">
                      {({ fieldsHelper, formik }) => (
                        <>
                          <b2x.FormGroup {...fieldsHelper.email.formGroup}>
                            <b2x.TextInput {...fieldsHelper.email.textInput} />
                          </b2x.FormGroup>
                          <b2x.FormGroup {...fieldsHelper.checkbox.formGroup}>
                            <b2x.Checkbox className="extra-small text-dark" {...fieldsHelper.checkbox.checkbox} />
                          </b2x.FormGroup>
                          <div className="d-grid">
                            <b2x.Button {...fieldsHelper.buttons.submit} />
                          </div>
                        </>
                      )}
                    </b2x.CartEmailFormHelper>
                  </div>
 */}
                  <div className="text-center mb-3">
                    <h3 className="fw-bold mb-3">
                      {b2x.formatHtml(whoisContent?.body.continueAsGuest?.registerMe?.title)}
                    </h3>
                    <div className="mb-4">
                      {whoisContent?.body.continueAsGuest?.registerMe?.subTitle && (
                        <p>{b2x.formatHtml(whoisContent.body.continueAsGuest.registerMe.subTitle)}</p>
                      )}
                      {b2x.formatHtml(whoisContent?.body.continueAsGuest?.registerMe?.content)}
                    </div>
                  </div>
                  <div className="d-grid">
                    <Button label={t('misc.register')} onClick={handleButtonClick} variant="outline-primary" />
                  </div>
                </div>
              )}
              {register && (
                <div className="mb-4">
                  <h4 className="fw-bold mb-3">{b2x.formatHtml(whoisContent?.body.continueAsGuest?.title)}</h4>
                  <b2x.CustomerForm onCancel={handleCancelButtonClick} onSuccess={handleRegistrationSuccess} />
                </div>
              )}
            </Box>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </Page>
  );
};
