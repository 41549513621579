import './CircleProgressBar.scss';

import React from 'react';

export interface CircleProgressBarProps {
  barColor?: string;
  bgColor?: string;
  label?: string;
  size?: number;
  strokeLineCap?: 'square' | 'round';
  strokeWidth?: number;
  valueCurrent: number;
  valueFrom: number;
  valueTo: number;
}

export const CircleProgressBar = ({
  barColor = '#E91C24',
  bgColor = '#EFEFEF',
  label,
  size = 250,
  strokeLineCap = 'round',
  strokeWidth = 10,
  valueCurrent,
  valueFrom,
  valueTo,
}: CircleProgressBarProps) => {
  const valuePathBar = React.useRef<SVGPathElement>(null);

  React.useEffect(() => {
    const length = 360;

    const progress = (valueCurrent * 100) / valueTo / 100;
    const dashoffset = length * (1 - (progress > 0.01 ? progress : 0.01));

    if (valuePathBar.current) {
      valuePathBar.current.style.strokeDashoffset = `${dashoffset}`;
      valuePathBar.current.style.strokeDasharray = `${length}`;
    }
  }, [valueCurrent, valueTo]);

  return (
    <div className="circle-progress-bar position-relative">
      <div>
        <svg viewBox="0 0 196.4 166.9" width={size} xmlns="http://www.w3.org/2000/svg">
          <path
            className="bg"
            d="M39.1,147.1c-27.3-32.5-23.1-81.1,9.5-108.5,32.5-27.3,81.1-23.1,108.4,9.5,24,28.6,24,70.4,0,99"
            fill="none"
            stroke={bgColor}
            strokeLinecap={strokeLineCap}
            strokeWidth={strokeWidth}
          />
          <path
            className="bar"
            d="M39.1,147.1c-27.3-32.5-23.1-81.1,9.5-108.5,32.5-27.3,81.1-23.1,108.4,9.5,24,28.6,24,70.4,0,99"
            fill="none"
            ref={valuePathBar}
            stroke={barColor}
            strokeLinecap={strokeLineCap}
            strokeWidth={strokeWidth}
          />
        </svg>
      </div>
      <div className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center pt-4">
        <div className="text-center">
          <div className="fw-bold lh-sm" style={{ fontSize: 40 }}>
            {valueCurrent}
          </div>
          <div className="lh-sm">
            <div>{label}</div>
            <div>
              ({valueFrom} / {valueTo})
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
