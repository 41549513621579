import { b2x } from '@b2x/react/src';

import { AppContext } from './AppContext';
import { Footer } from './Footer';
import { Header } from './Header';
import { Icomoon } from './Icomoon';
import { ModalsAndOffcanvasses } from './ModalsAndOffcanvasses';
import { AccountHomePage } from './pages/AccountHomePage';
import { AccountPage } from './pages/AccountPage';
import { ArticlePage } from './pages/ArticlePage';
import { AssembledProductPage } from './pages/AssembledProductPage';
import { BlogPage } from './pages/BlogPage';
import { CartPage } from './pages/CartPage';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { CheckoutPage } from './pages/CheckoutPage';
import { DoubleOptInOkPage } from './pages/DoubleOptInOkPage';
import { Error404Page } from './pages/Error404Page';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { GiftCardPage } from './pages/GiftCardPage';
import { HomePage } from './pages/HomePage';
import { LandingTemplateAPage } from './pages/LandingTemplateAPage';
import { LandingTemplateBPage } from './pages/LandingTemplateBPage';
import { LoginPage } from './pages/LoginPage';
import { NewsletterSubscribePage } from './pages/NewsletterSubscribePage';
import { ProductPage } from './pages/ProductPage';
import { RegistrationEmployeePage } from './pages/RegistrationEmployeePage';
import { RegistrationPage } from './pages/RegistrationPage';
import { RegistrationVetPage } from './pages/RegistrationVetPage';
import { SearchPage } from './pages/SearchPage';
import { ServicePage } from './pages/ServicePage';
import { TemplatePage } from './pages/TemplatePage';
import { ThankYouPage } from './pages/ThankYouPage';
import { ThankYouPageOrderFailed } from './pages/ThankYouPageOrderFailed';
import { UpdateCustomerRequiredFieldsPage } from './pages/UpdateCustomerRequiredFieldsPage';
import { WhoisPage } from './pages/WhoisPage';
import { SalesforceChat } from './SalesforceChat';

export const App = () => {
  return (
    <AppContext>
      {(appContext, appStaticContext) => (
        <b2x.App
          onAddToCartSuccess={appStaticContext.showCartOffcanvas}
          onAddToWishlistAsGuestButtonClick={appStaticContext.showAccountOffcanvas}
          onLoginActionCallback={appStaticContext.showAccountOffcanvas}
        >
          <ModalsAndOffcanvasses />
          <Icomoon />
          <div className="d-flex flex-column flex-grow-1">
            <Header />
            <div className="flex-grow-1">
              <b2x.router.MainRouter
                codeMappings={{
                  SITEMAP: <HomePage />,
                  SITE_ACCOUNT: <AccountHomePage />,
                  SITE_CCN_AREA: { component: <AccountPage enableReceiverColumn />, separateRouter: true },
                  SITE_CUSTOMER_CARE: { component: <ServicePage />, separateRouter: true },
                  SITE_LEGAL_AREA: { component: <ServicePage />, separateRouter: true },
                  SITE_LOGIN: <LoginPage />,
                  SITE_NEWSLETTER: <NewsletterSubscribePage />,
                  SITE_PICK_AND_MIX: <LandingTemplateBPage />,
                  SITE_REGISTER: <RegistrationPage />,
                  SITE_REGISTER_EMPLOYEE: <RegistrationEmployeePage />,
                  SITE_REGISTER_VET: <RegistrationVetPage />,
                  SITE_SEARCH: <SearchPage />,
                  SITE_TEMPLATE: <TemplatePage />,
                }}
                error404Page={<Error404Page />}
                fixedMappings={{
                  cart: <CartPage />,
                  'change-password': <ChangePasswordPage />,
                  checkout: <CheckoutPage />,
                  'double-opt-in-ok': <DoubleOptInOkPage />,
                  'forgot-password': <ForgotPasswordPage />,
                  'order-ko': <ThankYouPageOrderFailed />,
                  'order-ok': <ThankYouPage />,
                  template: <TemplatePage />,
                  'update-customer-required-fields': <UpdateCustomerRequiredFieldsPage />,
                  whois: <WhoisPage />,
                }}
                templateMappings={{
                  BLOG_PAGE_CONTENT_TYPE: <BlogPage />,
                  LANDING_TEMPLATE_A_PAGE_CONTENT_TYPE: <LandingTemplateAPage />,
                  LANDING_TEMPLATE_B_PAGE_CONTENT_TYPE: <LandingTemplateBPage />,
                }}
                typeMappings={{
                  CATALOG: <SearchPage />,
                  CONTENT: <ArticlePage />,
                  LISTING: <SearchPage />,
                  PRODUCT: (
                    <b2x.ProductTypeSwitch
                      assembled={<AssembledProductPage />}
                      default={<ProductPage />}
                      giftCard={<GiftCardPage />}
                      kit={<ProductPage />}
                    />
                  ),
                }}
              />
            </div>
            <Footer />
            {b2x.environment === 'PRODUCTION' && <SalesforceChat />}
            <div id="products-tiles-container"></div>
          </div>
        </b2x.App>
      )}
    </AppContext>
  );
};
